import React, {useState, useEffect} from "react";
import {UserCheck, HelpCircle, Tool, Users} from "react-feather";
import {GET} from "../../api/adminApi";
import {Chart} from "./Chart";
import {ChartjsBarChart, ChartjsBarChartTransparent} from "./Charts/chartjs";
import TopInstallations from "./Charts/TopInstallations";
import TopSales from "./Charts/TopSales";
import {ChartRegion} from "./Charts/ChartRegion";
import AllReports from "./Charts/AllReports";

function Main() {
    const [dashboardCounts, setDashboardCounts] = useState({});

    async function getDashboardCounts() {
        const data = await GET("/services/admin/api/dashboard-counts/");
        setDashboardCounts(data);
    }

    useEffect(() => {
        getDashboardCounts();
    }, []);

    return (
        <>
            <div
                style={{background: "#eef1ff", marginTop: "-0.4rem"}}
                className="p-3"
            >
                <div className="d-flex flex-wrap gap-4 justify-content-center mb-3">
                    <div
                        className="d-flex w-100 background-white align-items-center br-5 box-shadow"
                        style={{maxWidth: "240px", height: 85}}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center text-white h-100 br-5"
                            style={{background: "#6BC9C9", padding: "10%"}}
                        >
                            <UserCheck size={36}/>
                        </div>
                        <div className="text-center w-100 mb-2">
                            <p className="fz32 m-0">
                                {dashboardCounts?.salesCount
                                    ? dashboardCounts.salesCount
                                    : 0}
                            </p>
                            <p
                                ref={(node) => {
                                    if (node) {
                                        node.style.setProperty(
                                            "margin-top",
                                            "-10px",
                                            "important"
                                        );
                                    }
                                }}
                                className="fz12 m-0"
                            >
                                Контрагенты
                            </p>
                        </div>
                    </div>

                    <div
                        className="d-flex w-100 background-white align-items-center br-5 box-shadow"
                        style={{maxWidth: "240px", height: 85}}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center text-white h-100 br-5"
                            style={{background: "#FE6B61", padding: "10%"}}
                        >
                            <Users size={36}/>
                        </div>
                        <div className="text-center w-100 mb-2">
                            <p className="fz32 m-0">
                                {dashboardCounts?.masterCount
                                    ? dashboardCounts.masterCount
                                    : 0}
                            </p>
                            <p
                                ref={(node) => {
                                    if (node) {
                                        node.style.setProperty(
                                            "margin-top",
                                            "-10px",
                                            "important"
                                        );
                                    }
                                }}
                                className="fz12 m-0"
                            >
                                Мастеры
                            </p>
                        </div>
                    </div>

                    <div
                        className="d-flex w-100 background-white align-items-center br-5 box-shadow"
                        style={{maxWidth: "240px", height: 85}}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center text-white h-100 br-5"
                            style={{background: "#F9D247", padding: "10%"}}
                        >
                            <HelpCircle size={36}/>
                        </div>
                        <div className="text-center w-100 mb-2">
                            <p className="fz32 m-0">
                                {dashboardCounts?.salesRequestCount
                                    ? dashboardCounts.salesRequestCount
                                    : 0}
                            </p>
                            <p
                                ref={(node) => {
                                    if (node) {
                                        node.style.setProperty(
                                            "margin-top",
                                            "-10px",
                                            "important"
                                        );
                                    }
                                }}
                                className="fz12 m-0"
                            >
                                Запросы от контрагентов
                            </p>
                        </div>
                    </div>

                    <div
                        className="d-flex w-100 background-white align-items-center br-5 box-shadow"
                        style={{maxWidth: "240px", height: 85}}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center text-white h-100 br-5"
                            style={{background: "#57C8F0", padding: "10%"}}
                        >
                            <Tool size={36}/>
                        </div>
                        <div className="text-center w-100 mb-2">
                            <p className="fz32 m-0">
                                {dashboardCounts?.mastersRequestCount
                                    ? dashboardCounts.mastersRequestCount
                                    : 0}
                            </p>
                            <p
                                ref={(node) => {
                                    if (node) {
                                        node.style.setProperty(
                                            "margin-top",
                                            "-10px",
                                            "important"
                                        );
                                    }
                                }}
                                className="fz12 m-0"
                            >
                                Запросы от мастеров
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row d-flex mb-3">
                    <div className="flex justify-content-around">
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 ">
                                <h2 className="font-weight-350 fs-5 text-left">Статистика запросов по месяцам</h2>
                            </div>
                            <Chart/>
                        </div>
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 ">
                                <h2 className="font-weight-350 fs-5 text-left">Статистика запросов по регионам</h2>
                            </div>
                            <ChartRegion/>
                        </div>
                    </div>
                    <div className="flex justify-content-around mt-4">
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <h2 className="font-weight-350 fs-5 text-left">Месячная статистика по продажам</h2>
                            </div>
                            <ChartjsBarChart className="foo" style={{marginBottom: '20px'}}/>
                        </div>
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <h2 className="font-weight-350 fs-5 text-left">Квартальная статистика по продажам</h2>
                            </div>
                            <ChartjsBarChartTransparent className="foo" style={{marginBottom: '20px'}}/>
                        </div>
                    </div>
                    <div className="flex justify-content-around mt-4">
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <h2 className="font-weight-350 fs-5 text-left">Топ 20 по продажам</h2>
                            </div>
                            <TopSales/>
                        </div>
                        <div
                            className="p-2 w-48 d-flex flex-column background-white align-items-center box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <h2 className="font-weight-350 fs-5 text-left">Топ 20 по установкам</h2>
                            </div>
                            <TopInstallations/>
                        </div>
                    </div>
                    <div className="flex justify-content-around p-4">
                        <div
                            className="p-2 w-100 d-flex flex-column background-white align-items-center px-4 box-shadow"
                        >
                            <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <h2 className="font-weight-350 fs-5 text-left">Отчет по объектам</h2>
                            </div>
                            <AllReports/>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Main;
