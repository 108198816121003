import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Save, XCircle } from "react-feather";
import { useForm } from "react-hook-form";
import {PGET, PUT} from "../../../api/adminApi";
import Select from "react-select";
import master from "../Masters/Master";
import bonus from "../Bonus/Bonus";

export default function Bonused({
                                         modal: { show, masterId },
                                         setModal,
                                         getItems,
                                     }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm();
    const [bonusOptions, setBonusOptions] = useState([]);
    const [bonusOpt, setBonusOpt] = useState(null);
    function onHide() {
        setModal({ show: false, masterId: null });
        clearErrors();
    }

    function selectBonus(selectedOpt) {
        if (selectedOpt !== bonusOpt) {
            setBonusOpt(selectedOpt);
        }
    }
    async function assign({ manager, object }) {
        const res = await PUT(`/services/admin/api/codes-bonused/${masterId}`, {
            id: masterId,
            bonusId: bonusOpt.id,
        });
        if (res && res.success === true) {
            getItems();
            onHide();
        }
    }

    useEffect(() => {
        async function bonusHelper() {
            const res = await PGET("/services/admin/api/bonus-helper");

            if (res) {
                setBonusOptions(res.data);
            }
        }
        bonusHelper();
    }, []);
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Вы действительно хотите сделать бонусным этот товар?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(assign)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100 ">
                                    <div className="mr-md-3 w-25 flex items-center">
                                        <label className="mb-0">Выберите бонус</label>
                                    </div>
                                    <Select
                                        {...register("object", {
                                            required: true,
                                            value: bonusOpt,
                                        })}
                                        value={bonusOpt}
                                        placeholder="Выберите бонус"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={bonusOptions}
                                        onChange={selectBonus}
                                        className="w-75"
                                    />
                                </div>
                                {errors.object && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
