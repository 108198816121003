import React from "react";
import axios from "axios";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { GUESTPOST } from "../../api/api";
import { useForm } from "react-hook-form";

const AdminLogin = () => {
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm();
   let history = useHistory();
   async function login(data) {
      const response = await GUESTPOST("/auth/login", data);
      const baseURL =
         window.location.hostname === "localhost"
            ? "https://bo.airfelsales.uz"
            : "https://" + window.location.hostname;
      const user = await axios.get(baseURL + "/services/uaa/api/account", {
         headers: { Authorization: "Bearer " + response.access_token },
      });
      // user = JSON.parse(user.data)
      // const user = await GET('/services/uaa/api/account')
      for (let i = 0; i < user.data.authorities.length; i++) {
         if (user.data.authorities[i] === "ROLE_ADMIN") {
            data.role = "ADMIN";
            localStorage.setItem("admin_access_token", response.access_token);
            localStorage.setItem(
               "admin_tokenTime",
               JSON.stringify(new Date().getTime())
            );
            localStorage.setItem("admin_user", JSON.stringify(data));
            history.push("/admin/main");
         }
         if (user.data.authorities[i] === "ROLE_OPERATOR") {
            data.role = "OPERATOR";
            localStorage.setItem("operator_user", JSON.stringify(data));
            localStorage.setItem(
               "operator_tokenTime",
               JSON.stringify(new Date().getTime())
            );
            localStorage.setItem(
               "operator_access_token",
               response.access_token
            );
            history.push("/operator/orders");
         }
      }
   }
   return (
      <div className="container-fluid">
         <div className="row">
            <div
               className="col-md-6 h-100vh d-flex align-items-center justify-content-center"
               style={{ backgroundColor: "#BC0116" }}
            >
               <Image
                  src="/img/logo2.png"
                  style={{ width: "400px" }}
                  className="w-inherit"
                  alt=""
               />
            </div>
            <div className="col-md-6 h-100vh container d-flex justify-content-center flex-column background-white">
               <form
                  autoComplete="off"
                  method="POST"
                  onSubmit={handleSubmit(login)}
                  action=""
               >
                  <div
                     className="fz30 d-flex justify-content-start ml-5 mb-4"
                     style={{ fontWeight: 350, color: "#262134" }}
                  >
                     Войти в систему
                  </div>
                  <div className="mx-5 mb-4">
                     <input
                        autoComplete="off"
                        {...register("username", {
                           required: true,
                           minLength: 4,
                        })}
                        type="text"
                        className="input-underline fz15 w-75 p-2"
                        placeholder="Имя пользователя"
                     />
                     {errors.username?.type === "required" && (
                        <div className="text-danger mt-2">
                           Обязательное поле
                        </div>
                     )}
                     {errors.username?.type === "minLength" && (
                        <div className="text-danger mt-2">
                           Поле должно быть не менее 4 символов
                        </div>
                     )}
                  </div>
                  <div className="mx-5 mb-4">
                     <input
                        autoComplete="false"
                        {...register("password", {
                           required: true,
                           maxLength: 20,
                           minLength: 4,
                        })}
                        type="password"
                        className="input-underline fz15 w-75 p-2"
                        placeholder="Пароль"
                     />
                     {errors.password?.type === "required" && (
                        <div className="text-danger mt-2">
                           Обязательное поле
                        </div>
                     )}
                     {errors.password?.type === "minLength" && (
                        <div className="text-danger mt-2">
                           Поле должно быть не менее 4 символов
                        </div>
                     )}
                  </div>
                  <div className="mx-5">
                     <button
                        type="submit"
                        className="btn w-75"
                        style={{
                           border: "1px solid #5C3EBA",
                           borderRadius: "3px",
                        }}
                     >
                        <div>
                           <span className="mr-1" style={{ color: "#5C3EBA" }}>
                              Войти
                           </span>{" "}
                           <Image src="/img/coolicon.png" alt="" />
                        </div>
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
};

export default AdminLogin;
