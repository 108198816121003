import React, { useEffect, useState } from "react";
import { PGET } from "../../../api/adminApi";

function AllReports() {
    const [items, setItems] = useState([]);

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/object-report-dashboard",
            true
        );
        setItems(data.data);
    }

    useEffect(() => {
        getItems();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="w-100">
            <div className="pb-4 pt-2">
                <div style={{ overflowX: "auto" }}>
                    <table className="table background-white rounded br-5">
                        <thead>
                        <tr className="backgroung-grey">
                            <th style={{width: "30px"}} className="text-center">
                                №
                            </th>
                            <th className="text-start">Название</th>
                            <th className="text-start">Описание</th>
                            <th className="text-center">Все привязанные</th>
                            <th className="text-center">Количество установок</th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto" }}>
                        {items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">
                                        {index + 1}
                                    </td>
                                    <td className="text-start">{item.name}</td>
                                    <td className="text-start">{item.description}</td>
                                    <td className="text-center">{item.countAll}</td>
                                    <td className="text-center">{item.count}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AllReports;
