import React, { useState } from "react";
import { Save, XCircle } from "react-feather";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {POST, PUT} from "../../../api/adminApi";
import {Link} from "react-router-dom";

export default function CommentMaster({
                                      modal: { show, item },
                                      setModal,
                                      getItems,
                                      statusOptions,
                                  }) {
    const [statusOpt, setStatusOpt] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm();
    console.log(item)
    async function changeStatus(data) {
        const response = await  PUT("/services/admin/api/master-comment/",
            {
                comment: data.comment,
                id: item.id,
            }
        );
        if (response && response.success === true) {
            getItems();
            onHide();
        }
    }

    function onHide() {
        setModal({ show: false, item: null });
        setStatusOpt(null);
        setValue("comment", "");
        clearErrors();
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить комментарии
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Комментарии</label>
                                    </div>
                                    <textarea
                                        {...register("comment", {})}
                                        type="text"
                                        placeholder=""
                                        className="input-control w-75"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
