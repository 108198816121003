import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { Search, Users, Lock, Unlock } from "react-feather";

import { PGET, PUT } from "../../../api/adminApi";

function User() {
   const [items, setItems] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
      +localStorage.getItem("currentPageUsers") || 0
   ); //for numeration and saving currentPage after refresh

   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };

   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPageUsers", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected);
      localStorage.setItem("currentPageUsers", data.selected); //for saving currentPage after refresh
   }

   async function getItems() {
      const data = await PGET("/services/admin/api/user-pageList", true, {
         page: currentPage,
         size: 10,
         search: value,
      });
      setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
      setItems(data.data);
   }

   async function handleActivate(userId) {
      const res = await PUT("/services/admin/api/user-activate/" + userId);

      if (res) {
         getItems();
      }
   }

   useEffect(() => {
      getItems();
   }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div style={{ background: "#eef1ff", marginTop: "-0.9rem" }}>
         <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
            <Users className="mr-2" color="#5C3EBA" /> <span>Пользователи</span>
         </div>

         <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-between">
               {/* <Link to="/admin/user/create">
                  <button className="btn btn-success d-flex align-items-center box-shadow br-5">
                     <PlusCircle size={18} className="mr-1" />
                     <div>Добавить новый</div>
                  </button>
               </Link> */}
               <div></div> {/*empty div*/}
               <div className="position-relative">
                  <Search
                     size={14}
                     color="#9D9BA3"
                     className="input-search-icon"
                  />
                  <DebounceInput
                     minLength={3}
                     debounceTimeout={400}
                     value={value}
                     onChange={handleSearchInput}
                     type="text"
                     className="input-search box-shadow br-5"
                     size={24}
                     placeholder="Поиск"
                  />
               </div>
            </div>
            <div style={{ overflowX: "auto" }}>
               <table className="table background-white rounded box-shadow br-5">
                  <thead>
                     <tr className="backgroung-grey">
                        <th style={{ width: "30px" }} className="text-center">
                           №
                        </th>
                        <th className="text-start">ФИО</th>
                        <th className="text-start">Номер телефона</th>
                        <th className="text-start">Время регистрации</th>
                        <th className="text-start">Этап регистрации</th>
                        <th className="text-center">Язык</th>
                        <th className="text-center">Тип</th>
                        <th className="text-center">Общий балл</th>
                        <th className="text-center">Статус</th>
                        <th></th>
                     </tr>
                  </thead>

                  <tbody style={{ overflowX: "auto" }}>
                     {items?.map((item, idx) => {
                        return (
                           <tr key={item.id}>
                              <td className="text-center">
                                 {currentPage === 0
                                    ? idx + 1
                                    : currentPage * 10 + idx + 1}
                              </td>
                              <td className="text-start">{item.fullName}</td>
                              <td className="text-start">{item.phone}</td>
                              <td className="text-start">{item.createdDate}</td>
                              <td className="text-start">
                                 {item.registrationStep}
                              </td>
                              <td className="text-center">{item.language}</td>
                              <td className="text-center">{item.userType}</td>
                              <td className="text-center">
                                 {item.earnedPoint}
                              </td>
                              <td
                                 className={`text-center ${
                                    item.activated
                                       ? "text-success"
                                       : "text-danger"
                                 }`}
                              >
                                 {item.statusName}
                              </td>
                              <td className="text-center">
                                 <div className="d-flex justify-content-center">
                                    {item.activated ? (
                                       <button
                                          onClick={() => {
                                             handleActivate(item.id);
                                          }}
                                          className="btn btn-table box-shadow br-5"
                                          style={{ backgroundColor: "#F4F4F5" }}
                                       >
                                          <Lock color={"#E63950"} size={16} />
                                       </button>
                                    ) : (
                                       <button
                                          onClick={() => {
                                             handleActivate(item.id);
                                          }}
                                          className="btn btn-table box-shadow br-5"
                                          style={{ backgroundColor: "#F4F4F5" }}
                                       >
                                          <Unlock color={"#189ED3"} size={16} />
                                       </button>
                                    )}
                                 </div>
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            {pageCount > 1 && (
               <ReactPaginate
                  forcePage={currentPage}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={paginate}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item box-shadow"}
               />
            )}
         </div>
      </div>
   );
}

export default User;
