import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import Select from 'react-select';
import { Search, ShoppingBag, Check, XCircle, Phone } from 'react-feather';
import { toast } from 'react-toastify';
import io from 'socket.io-client'

import { formatMoney } from '../../../helper'
import { PGET, GET } from '../../../api/operatorApi'

function Orders() {
	let audio = new Audio("/audio.mp3")
	const socketRef = useRef(null)

	const [pageCount, setPageCount] = useState(0)
	const [value, setValue] = useState('')
	const [couriers, setCouriers] = useState([])
	const [item, setItem] = useState({ productList: [] })
	const [items, setItems] = useState([])

	const [showNewModal, setShowNewModal] = useState(false)
	const [showCancelModal, setShowCancelModal] = useState(false)
	const [showСonfirmationModal, setShowСonfirmationModal] = useState(false)
	const [totalAmount, setTotalAmount] = useState(0)

	async function search(e) {
		const data = await PGET('/services/admin/api/orders-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setValue(e.target.value)
		for (let i = 0; i < data.data.length; i++) {
			data.data[i].orderDate = moment(data.data[i].orderDate).format("DD.MM.YY HH:mm")
		}
		setItems(data.data);
	};

	async function paginate(payload) {
		const data = await PGET('/services/admin/api/orders-pageList', true, { page: payload.selected, size: 20, search: value })
		setItems(data.data);
	};

	async function getCouriers() {
		const data = await GET('/services/admin/api/couriers-helper', true)
		setCouriers(data)
	};

	function select(index) {
		var obj = item
		obj.productList[index].selected = !obj.productList[index].selected
		setItem(obj)
	}

	function getItem(id) {
		socketRef.current.emit('operator-orders-get-by-id', { 'id': id })
	}

	function orderUpdate() {
		var itemCopy = { ...item }
		itemCopy.orderStatus = 1

		socketRef.current.emit('operator-orders-update', itemCopy)
		setShowСonfirmationModal(false)
	}

	function checkStatus(status) {
		var caseStatus = ""
		switch (status) {
			case 0:
				caseStatus = 'Новый'
				break;
			case 1:
				caseStatus = 'Подтвержден менеджером'
				break;
			case 2:
				caseStatus = 'Подтвержден оператором'
				break;
			case 3:
				caseStatus = 'Готово к доставке'
				break;
			case 4:
				caseStatus = 'Заказ забран курьером'
				break;
			case 5:
				caseStatus = 'Заказ доставлен клиенту'
				break;
			case 6:
				caseStatus = 'Отменен оператором'
				break;
			case 7:
				caseStatus = 'Отменен менеджером'
				break;
			default:
				break;
		}
		return caseStatus
	}

	function checkStatusClass(status) {
		var className = ""
		switch (status) {
			case 0:
				className = 'bg-red'
				break;
			case 1:
				className = 'bg-yellow'
				break;
			case 3:
				className = 'bg-orange'
				break;
			case 4:
				className = 'bg-pink'
				break;
			case 5:
				className = 'bg-success'
				break;
			case 6:
				className = 'bg-muted'
				break;
			case 7:
				className = 'bg-grey-dark'
				break;
			default:
				className = 'bg-grey-dark'
				break;
		}
		return className
	}

	useEffect(() => {
		localStorage.setItem("ordersExist", 0)
		getCouriers()
		const baseURL = window.location.hostname === 'localhost' ?  'https://colibri24.uz/operator-orders' : 'https://' + window.location.hostname + "/operator-orders";

		socketRef.current = io.connect(baseURL);

		socketRef.current.on('operator-orders', (data) => {
			console.log('orders', data);
			//console.log('items', items);
			//console.log('local', localStorage.getItem('ordersExist'));
			if (
				Number(localStorage.getItem('ordersExist')) === 1 &&
				Number(localStorage.getItem('ordersSize')) !== data.length &&
				Number(data[0]['orderStatus']) === 0
			) {
				audio.play()
				toast.dismiss()
				toast.success(`Поступил новый заказ ID: ${data[data.length - 1]['id']}`, {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			}

			if (data.length > 0) {
				localStorage.setItem("ordersExist", 1)
				localStorage.setItem("ordersSize", data.length)
				setItems(data)
			}
		})

		socketRef.current.on('operator-orders-get-by-id', (data) => {
			console.log('order', data);
			data.orderDate = moment(data.orderDate).format("DD.MM.YY HH:mm")
			var _totalAmount = 0
			for (let i = 0; i < data.productList.length; i++) {
				data.productList[i].selected = false
				_totalAmount += data.productList[i].productPrice * data.productList[i].quantity
			}
			setTotalAmount(formatMoney(_totalAmount))
			setItem(data)
			setShowСonfirmationModal(true)
		})

		return () => {
			//socketRef.current.close()
			socketRef.current.disconnect()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="background-white my-2 mx-3 box-shadow br-5">
			<div className="border-bottom px-3 py-3 my-2 d-flex justify-content-between">
				<div className='d-flex align-items-center'>
					<ShoppingBag className='mr-2' color='#5C3EBA' /> <span className="fz20">Заказы</span>
				</div>
				<div className="">
					<div className="position-relative">
						<Search size={14} color='#9D9BA3' className="input-search-icon" />
						<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
					</div>
				</div>
			</div>
			<div className="d-flex overflow-x-auto mb-3 mx-3">
				<div className="btn bg-black br-5 mr-2">
					Все 999
				</div>
				<div className="btn bg-red br-5 mr-2">
					Новый 999
				</div>
				<div className="btn bg-yellow br-5 mr-2">
					Готовится 999
				</div>
				<div className="btn bg-orange br-5 mr-2">
					Ждет отправки 999
				</div>
				<div className="btn bg-pink br-5 mr-2">
					В пути 999
				</div>
				<div className="btn bg-grey-dark br-5 mr-2">
					Отменен 999
				</div>
				<div className="btn bg-black br-5 mr-2">
					Закрыт 999
				</div>
			</div>
			<div className="px-3 pb-4">
				<div style={{ overflowX: 'auto' }}>
					<table className="table table-bordered">
						<thead>
							<tr className='backgroung-grey'>
								<th className="text-center">Номер заказа</th>
								<th className="text-center">Время доставки</th>
								<th className="text-center">Клиента</th>
								<th className="text-center">Кухня</th>
								<th className="text-center">Курьер заказа</th>
								<th className="text-center">Сумма заказа</th>
							</tr>
						</thead>
						<tbody style={{ overflowX: 'auto' }}>
							{items.length > 0 &&
								items.map((item) => {
									return (
										<tr key={item.id} className="cursor" onClick={() => getItem(item.id)}>
											<td className="text-center">
												<div>
													<div className="fz24"><b>{item.id}</b></div>
												</div>
											</td>
											<td className="text-center">
												<div className="fz24">
													{item?.orderDate?.hour + ':' + item?.orderDate?.minute}
												</div>
											</td>
											<td className="text-center">
												<div className="fz20">
													{item.userName}
												</div>
												<div>
													<div className="operator-number">
														<div className="bg-blue mr-1">
															<Phone size={16} color="#fff" style={{ margin: '4px' }} />
														</div>
														<span className="color-blue mr-1">+{item.userPhone} </span>
													</div>
												</div>
											</td>
											<td className="text-center">
												<div className="fz20">
													{item.branchName}
												</div>
												<div>
													<div className="operator-number">
														<div className="bg-blue mr-1">
															<Phone size={16} color="#fff" style={{ margin: '4px' }} />
														</div>
														<span className="color-blue mr-1">+{item.courierPhone}</span>
													</div>
												</div>
											</td>
											<td className="text-center">
												{item.courierId ?
													<>
														<div className="fz20">
															{item.courierName}
														</div>
														<div>
															<div className="operator-number">
																<div className="bg-blue mr-1">
																	<Phone size={16} color="#fff" style={{ margin: '4px' }} />
																</div>
																<span className="color-blue mr-1">+{item.courierPhone}</span>
															</div>
														</div>
													</>
													:
													<span className="text-danger">Курьер не назначен</span>
												}
											</td>
											<td>
												<div>
													<div className="fz24"><b>{formatMoney(item.orderPrice)}</b></div>
													<div>
														<span className={"table-btn " + checkStatusClass(item.orderStatus)}>
															{checkStatus(item.orderStatus)}
														</span>
													</div>
												</div>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
				{pageCount > 1 &&
					<ReactPaginate
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={1}
						pageRangeDisplayed={1}
						onPageChange={paginate}
						containerClassName={'pagination'}
						activeClassName={'active'}
						pageClassName={'page-item'}
					/>
				}
			</div>

			{/* ONE */}

			<Modal
				show={showNewModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setShowNewModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter" className='fz20 font-weight-normal w-100 d-flex justify-content-between'>
						<div>
							Номер заказа №{item.orderNumber}
						</div>
						<div>
							<span className="table-btn bg-blue">
								Новый
							</span>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="bg-light p-2 mb-3">
						<div className="d-flex border-bottom-dashed pb-3 mx-3 mb-2">
							<div className="w-50">
								<b>Заказ принят: </b>
								<span className="font-weight-350 ml-2">00:00 - 00/00/00</span>
							</div>
							<div className="w-50">
								<b>Время доставки:</b>
								<span className="font-weight-350 ml-2">00:00 - 00/00/00</span>
							</div>
						</div>
						<div className="d-flex mx-3 mb-2">
							<div className="w-50 d-flex flex-column">
								<b>Кухня:</b>
								<div>Название кухни</div>
								<div>+888-88-888-88-88 Адрес кухни</div>
							</div>
							<div className="w-50 d-flex flex-column">
								<b>Кухня:</b>
								<div>Название кухни</div>
								<div>+888-88-888-88-88 Адрес кухни</div>
							</div>
						</div>
					</div>
					<div className="p-2 border-bottom px-3 d-flex flex-column">
						<div className="d-flex mb-3">
							<div className="d-flex align-items-center mr-2">
								<input type="checkbox" id="cb1" className="cb1" />
								<label htmlFor="cb1"></label>
								<Check htmlFor="cb1" className="position-absolute" width={16} height={14} />
							</div>
							<div className="color-grey">
								Есть все
							</div>
						</div>
						{
							item &&
							item.productList.map((item, index) => {
								return (
									<div className={"d-flex w-100 mb-1 " + (item.selected ? "bg-grey p-1" : "")} key={item.productId}>
										<div className="d-flex align-items-center mr-2">
											<input onChange={(e) => select(index)} type="checkbox" id={item.id} checked={item.selected} className="cb1" />
											<label htmlFor={item.id}></label>
											<Check className="position-absolute" width={16} height={14} />
										</div>
										<div className="d-flex align-items-center px-2 w-100">
											<img src={"https://colibri24.uz" + item.productImageUrl} height={64} width={64} className="obj-fit-cover br-5" alt="" />
											<div className="ml-3">
												{item.productName}
											</div>
										</div>
										<div className="d-flex align-items-center justify-content-end px-2 w-100">
											{item.quantity} x {formatMoney(item.productPrice)} сум =
											<span className="text-dark font-weight-bold"> {formatMoney(item.quantity * item.productPrice)} сум </span>
										</div>
									</div>
								)
							})

						}
						<div className="d-flex fz20 border-top-dashed justify-content-between align-items-center py-4 mt-2">
							<span>Сумма заказа:</span>
							{totalAmount} сум
						</div>
					</div>
					<div className="p-2 mb-2 d-flex justify-content-end w-100">
						<button type='submit' className="btn btn-red br-5 mr-3 w-100">Отказать</button>
						<button type='submit' className="btn btn-purple w-100">Отправить</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* TWO */}

			<Modal
				show={showСonfirmationModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setShowСonfirmationModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter" className='fz20 font-weight-normal w-100 d-flex justify-content-between'>
						<div>
							Номер заказа №{item.orderNumber}
						</div>
						<div>
							<span className={"table-btn " + checkStatusClass(item.orderStatus)}>
								{checkStatus(item.orderStatus)}
							</span>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="bg-light p-2 mb-3">
						<div className="d-flex justify-content-between pb-3 mx-3 mb-2">
							<div>
								<b>Заказ принят: </b>
								<span className="font-weight-350 ml-2">00:00 - 00/00/00</span>
							</div>
							<div>
								<b>Время доставки:</b>
								<span className="font-weight-350 ml-2">00:00 - 00/00/00</span>
							</div>
						</div>
						<div className="d-flex flex-column mx-3 mb-2">
							<div className="d-flex align-items-center justify-content-start mb-2">
								<div className="mr-2"><b>Клиент: </b></div>
								<div className="mr-2">Имя клиента - </div>
								<div className="operator-number ml-0 mr-2">
									<div className="bg-blue mr-1">
										<Phone size={16} color="#fff" style={{ margin: '4px' }} />
									</div>
									<span className="color-blue mr-2">+888-88-888-88-88 </span>
								</div>
								<div>
									Адрес доставки
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-start">
								<div className="mr-2"><b>Кухня: </b></div>
								<div className="mr-2">Название кухни - </div>
								<div className="operator-number ml-0 mr-2">
									<div className="bg-blue mr-1">
										<Phone size={16} color="#fff" style={{ margin: '4px' }} />
									</div>
									<span className="color-blue mr-2">+888-88-888-88-88 </span>
								</div>
								<div>
									Адрес кухни
								</div>
							</div>
						</div>
					</div>
					<div className="border-bottom  d-flex flex-column">
						<div className="p-2 px-3 mb-3">
							{item &&
								item.productList.map((item, index) => {
									return (
										<div className={"d-flex w-100 mb-1 " + (item.selected ? "bg-grey p-1" : "")} key={item.productId}>
											<div className="d-flex align-items-center mr-2">
												<input onChange={(e) => select(index)} type="checkbox" id={item.id} checked={item.selected} className="cb1" />
												<label htmlFor={item.id}></label>
												<Check className="position-absolute" width={16} height={14} />
											</div>
											<div className="d-flex align-items-center px-2 w-100">
												{item.productName}
											</div>
											<div className="d-flex align-items-center justify-content-end px-2 w-100">
												{item.quantity} x {formatMoney(item.productPrice)} сум =
												<span className="text-dark font-weight-bold"> {formatMoney(item.quantity * item.productPrice)} сум </span>
											</div>
											<div className="d-flex align-items-center justify-content-end px-2 w-100">
												<XCircle size={17} color="#E63950" />
											</div>
										</div>
									)
								})

							}
						</div>
						<div className="bg-light">
							<div className="d-flex fz20 border-bottom-dashed justify-content-between align-items-center p-3">
								<span>Сумма заказа:</span>
								{totalAmount} сум
							</div>
							<div className="d-flex justify-content-between p-3">
								<div className="fz20">Для курьера:</div>
								<div className="w-50">
									<Select
										options={couriers}
										value={couriers.find(option => option.id === item.courierId) || ''}
										placeholder="Выберите курьера"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										className="basic-multi-select w-100"
										onChange={(option) => setItem({ ...item, 'courierId': option.id })}
									/>
								</div>
							</div>
						</div>
					</div>
					{item.orderStatus === 0 &&
						<div className="p-2 mb-2 d-flex justify-content-end w-100">
							<button type='button' className="btn bg-light font-weight-600 color-red br-5 mr-3 w-100"
								onClick={() => setShowСonfirmationModal(false) + setShowCancelModal(true)}>
								Отменить заказ полностью
							</button>
							<button type='button' className="btn btn-purple w-100"
								onClick={() => orderUpdate()}
							>Подтвердить заказ</button>
						</div>
					}
				</Modal.Body>
			</Modal>

			{/* THREE */}
			<Modal
				show={showCancelModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setShowCancelModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter" className='fz20 font-weight-normal'>
						<div>
							Отмена заказа №{item.orderNumber}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="p-3">
						<div className="fz20 mb-3">
							Причины отмены:
						</div>
						<div>
							<div>
								<div className="mb-3">
									<input type="radio" id="comment-1" name='comment' className="custom-rb custom-rb-purple" />
									<label className="mb-0" htmlFor="comment-1">Клиент отказался от заказа</label>
								</div>
								<div className="mb-3">
									<input type="radio" id="comment-2" name='comment' className="custom-rb custom-rb-purple" />
									<label className="mb-0" htmlFor="comment-2">Не получается дозвониться до клиента</label>
								</div>
								<div className="mb-3">
									<input type="radio" id="comment-3" name='comment' className="custom-rb custom-rb-purple" />
									<label className="mb-0" htmlFor="comment-3">Нет в наличии</label>
								</div>
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label w-max-content mr-md-3"><label className="mb-0">Другое</label></div>
									<input type="text" placeholder="Другое" className="input-control" />
								</div>
							</div>
						</div>
					</div>
					<div className="p-2 mb-2 d-flex justify-content-end w-100">
						<button type='submit' className="btn btn-red br-5 mr-3 w-100">Отменить</button>
						<button type='submit' className="btn btn-purple w-100">Сохранить</button>
					</div>
				</Modal.Body>
			</Modal>

		</div>
	)
}


export default Orders