import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save, CheckCircle} from "react-feather";
import {useForm} from "react-hook-form";
import {GET, POST, PUT} from "../../../api/adminApi";
import {toast} from "react-toastify";
import Select from "react-select";

export default function NewProductAddUpdate() {
    const {id: productId} = useParams();
    const history = useHistory();
    const [brands, setBrands] = useState([{id: 0, name: "Все"}]);
    const [brand, setBrand] = useState(brands[0]);
    const [models, setModels] = useState([{id: 0, name: "Все"}]);
    const [model, setModel] = useState(null);
    const [isAssigned, setIsAssigned] = useState(false);
    const [data, setData] = useState({
        approved: 0,
    });
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();

    async function getBrands() {
        const response = await GET("/services/admin/api/brand-helper");
        if (response?.length > 0) {
            setBrands(response);
        }
    }

    async function getModels() {
        const response = await GET(`/services/admin/api/model-helper/${brand.id}`);
        if (response?.length > 0) {
            setModels(response);
        }
    }

    function selectBrand(selectedOption) {
        if (selectedOption !== brand) {
            setBrand(selectedOption);
        }
    }

    function selectModel(selectedOption) {
        if (selectedOption !== brand) {
            setModel(selectedOption);
        }
    }

    function onSubmit(values) {
        const obj = {
            ...data,
            name: values.name,
            point: values.point,
            pointSales: values.pointSales,
            brandId: brand ? brand.id : null,
            modelId: model ? model.id : null,
            assignedToObject: isAssigned
        };

        async function create() {
            if (productId) {
                const response = await PUT("/services/admin/api/product", obj);
                if (response?.success) {
                    history.push("/admin/products");
                }
            } else {
                const response = await POST("/services/admin/api/product", obj);
                console.log(response)
                if (response?.success) {
                    history.push("/admin/products");
                    toast.success("Успешно добавлен");
                }
            }
        }

        create()
    }

    async function getData() {
        const response = await GET("/services/admin/api/product/" + productId);
        console.log(response)
        if (response) {
            const {
                id,
                name,
                brandId,
                point,
                pointSales
            } = response;
            setValue("name", name);
            setValue("point", point);
            setValue("pointSales", pointSales);
            setData((prev) => ({
                ...prev,
                id,
            }));
            const getBrands = await GET("/services/admin/api/brand-helper");
            if (getBrands) {
                setBrands(getBrands);
                setBrand(
                    getBrands.find((item) => item?.id === item?.id)
                );
            }
                const getModels = await GET(
                    `/services/admin/api/model-helper/${brandId}`
                );
                if (getModels) {
                    setModels(getModels);
                    setModel(getModels.find((item) => item?.id === item?.id));
                }

        }
    }

    useEffect(() => {
        getModels();
    }, [brand]);

    useEffect(() => {
        if (productId) {
            getData();
        } else {
            getBrands();
            getModels();
        }
    }, []);

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-5 mt-5">
                {productId ? "Изменить" : "Добавить новый"}
            </h3>
            <div className="admin-card py-4">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="d-flex">
                        <div className="pt-12" style={{width: "500px", display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название</label>
                                    </div>
                                    <input
                                        {...register("name")}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Балл для мастера</label>
                                    </div>
                                    <input
                                        {...register("point")}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Балл для Конрагента</label>
                                    </div>
                                    <input
                                        {...register("pointSales")}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col align-items-end">
                                <div className="admin-field w-fit-content">
                                    <div className="mb-2 d-flex align-items-center gap-2">
                                        <label
                                            style={{width: "120px"}}
                                            className="color-grey text-end text-nowrap"
                                        >
                                            Бренд
                                        </label>
                                        <div style={{width: "300px", fontSize: "12px"}}>
                                            <Select
                                                {...register("brand", {
                                                    value: brand,
                                                })}
                                                value={brand}
                                                placeholder="Выберите тип"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                options={brands}
                                                onChange={selectBrand}
                                            />
                                            {errors.brand && (
                                                <div className="text-danger mt-1 fz12">
                                                    Обязательное поле
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="admin-field w-fit-content">
                                    <div className="mb-2 d-flex align-items-center gap-2">
                                        <label
                                            style={{width: "120px"}}
                                            className="color-grey text-end text-nowrap"
                                        >
                                            Модель
                                        </label>
                                        <div style={{width: "300px", fontSize: "12px"}}>
                                            <Select
                                                {...register("model", {
                                                    value: model,
                                                })}
                                                value={model}
                                                placeholder="Выберите сегмент"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                options={models}
                                                onChange={selectModel}
                                            />
                                            {errors.model && (
                                                <div className="text-danger mt-1 fz12">
                                                    Обязательное поле
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-field align-items-start" style={{marginLeft: 75}}>
                                <div className="d-flex align-items-center gap-2">
                                    <label
                                        style={{width: "120px"}}
                                        className="color-grey text-end text-nowrap"
                                    >
                                        Привязан или нет:
                                    </label>
                                    <div className="d-flex gap-2">
                                        <label className={`icon-label ${isAssigned === true ? "selected" : ""}`}>
                                            <input
                                                type="radio"
                                                {...register("assignedToObject")}
                                                value={true}
                                                defaultChecked={isAssigned === true}
                                                onChange={() => setIsAssigned(true)}
                                            />
                                            <CheckCircle size={16} className="mr-1"/>
                                            Да
                                        </label>
                                        <label className={`icon-label ${isAssigned === false ? "selected" : ""}`}>
                                            <input
                                                type="radio"
                                                {...register("assignedToObject")}
                                                value={false}
                                                defaultChecked={isAssigned === false}
                                                onChange={() => setIsAssigned(false)}
                                            />
                                            <XCircle size={16} className="mr-1"/>
                                            Нет
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to="/admin/products" className="mr-3">
                            <button className="btn btn-outline-purple d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            brand="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

