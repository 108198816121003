import React, {useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";

import {PGET, POST, PUT} from "../../../api/adminApi";
import {toast} from "react-toastify";

function PrizeAdd() {
    const {id} = useParams()
    const history = useHistory();
    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm();

    async function create(data) {
        if (id) {
            const response = await PUT("/services/admin/api/prize", {
                ...data,
                id: +id,
                point: +data.point,
            });

            if (response?.success) {
                history.push("/admin/prize");
            }
        } else {
            const response = await POST("/services/admin/api/prize", {
                ...data,
                point: + data.point,
            });


            if (response?.success === true) {
                history.push("/admin/prize");
                toast.success("Успешно");
            }
        }
    }

    async function getPrize() {
        const res = await PGET("/services/admin/api/prize/" + id);
        setValue("name", res?.data?.name);
        setValue("point", res?.data?.point);
        setValue("description", res?.data?.description);
    }

    useEffect(() => {
        if (id) {
            getPrize();
        }
    }, []);

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card py-4">
                <form onSubmit={handleSubmit(create)} autoComplete="off">
                    <div className="d-flex">
                        <div className="" style={{width: "500px"}}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название</label>
                                    </div>
                                    <input
                                        {...register("name", {required: true})}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.name && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Балл</label>
                                    </div>
                                    <input
                                        {...register("point", {required: true})}
                                        type="number"
                                        step={"1"}
                                        placeholder="1.0"
                                        className="input-control"
                                    />
                                </div>
                                {errors.point && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание</label>
                                    </div>
                                    <textarea
                                        {...register("description", {required: true})}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.description && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to="/admin/prize" className="mr-3">
                            <button className="btn btn-outline-purple d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PrizeAdd;
