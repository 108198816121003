import React, {useState} from "react";
import {Download, List} from "react-feather";
import {DOWNLOAD} from "../../../api/adminApi";
import DatePicker from "react-datepicker";

function ExportData() {
    const [dateRangeContragent, setDateRangeContragent] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDateContragent, endDateContragent] = dateRangeContragent;
    const [dateRangeMaster, setDateRangeMaster] = useState([
        new Date('2024-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDateMaster, endDateMaster] = dateRangeMaster;

    function monthAllAgents() {
        if (startDateContragent && endDateContragent) {
            const formattedStartDate = startDateContragent.toISOString().split('T')[0];
            const formattedEndDate = endDateContragent.toISOString().split('T')[0];

            const excelUrl = `/services/admin/api/contragent-report/download-monthly-all?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}`;
            const fileName = 'sales-report.xlsx';

            DOWNLOAD(excelUrl, fileName);
        } else {
            // Handle case where date range is not selected
            console.log("Please select a date range");
        }
    }

    function monthlyMasters() {
        if (startDateContragent && endDateContragent) {
            const formattedStartDate = startDateContragent.toISOString().split('T')[0];
            const formattedEndDate = endDateContragent.toISOString().split('T')[0];

            const excelUrl = `/services/admin/api/master-report/download-monthly-all?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}`;
            const fileName = 'master-report.xlsx'

            DOWNLOAD(excelUrl, fileName);
        } else {
            // Handle case where date range is not selected
            console.log("Please select a date range");
        }
    }

    return (
        <div style={{background: "#eef1ff", marginTop: "-0.9rem"}}>
            <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
                <List className="mr-2" color="#5C3EBA"/> <span>Экспорт отчетов в Excel</span>
            </div>
            <div className="px-3 pb-4 pt-2">
                <div style={{overflowX: "auto"}}>
                    <table className="table background-white box-shadow">
                        <thead>
                        <tr className="backgroung-grey">
                            <th style={{width: "30px"}} className="text-center">
                                №
                            </th>
                            <th className="text-start">Наименование отчета</th>
                            <th className="text-start"></th>
                            <th className="text-start">Скачать</th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        <tr>
                            <td className="text-center">
                                1
                            </td>
                            <td className="text-start">Ежемесячные - все контрагенты</td>
                            <td>
                                <div style={{width: 200}}>
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDateContragent}
                                        endDate={endDateContragent}
                                        onChange={(update) => {
                                            setDateRangeContragent(update);
                                        }}
                                        isClearable={true}
                                        className="datepicker-custom-input"
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button
                                        onClick={monthAllAgents}
                                        disabled={!startDateContragent || !endDateContragent}
                                        className={`btn btn-table mr-0 box-shadow`}
                                        style={{backgroundColor: "#F4F4F5"}}
                                    >
                                        <Download color={"#E63950"} size={16}/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                2
                            </td>
                            <td className="text-start">Ежемесячные - мастеры</td>
                            <td>
                                <div style={{width: 200}}>
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDateMaster}
                                        endDate={endDateMaster}
                                        onChange={(update) => {
                                            setDateRangeMaster(update);
                                        }}
                                        isClearable={true}
                                        className="datepicker-custom-input"
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button
                                        onClick={monthlyMasters}
                                        disabled={!startDateMaster || !endDateMaster}
                                        className="btn btn-table mr-0 box-shadow"
                                        style={{backgroundColor: "#F4F4F5"}}
                                    >
                                        <Download color={"#E63950"} size={16}/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ExportData;
