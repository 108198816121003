import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { XCircle, Save } from "react-feather";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { isValidPhoneNumber } from "react-phone-number-input";

import { POST, GET, PUT } from "../../../api/adminApi";

function ContragentAddUpdate() {
   const { id: contragentId } = useParams();
   const history = useHistory();
   const {
      register,
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
   } = useForm();
   const [data, setData] = useState({
      approved: 0,
   });
   const typeOptions = [
      { value: true, label: "Главный" },
      { value: false, label: "Сотрудник" },
   ];
   const [type, setType] = useState(typeOptions[0]);
   const [region, setRegion] = useState(null);
   const [city, setCity] = useState(null);
   const [bazar, setBazar] = useState(null);
   const [salePoint, setSalePoint] = useState(null);
   const [dealer, setDealer] = useState(null);
   const [parent, setParent] = useState(null);
   const [regions, setRegions] = useState([]);
   const [cities, setCities] = useState([]);
   const [bazars, setBazars] = useState([]);
   const [sale, setSale] = useState([]);
   const [dealers, setDealers] = useState([]);
   const [parents, setParents] = useState([]);
   const [phoneErr, setPhoneErr] = useState(null);

   function onSubmit(values) {
      const obj = {
         ...data,
         name: values.name,
         phone: values.phone.slice(1),
         smartupName: values.smartupName,
         description: values.description,
         parent: values.type.value,
         parentId: values.parent?.id,
         regionId: values.region?.id,
         cityId: values.city?.id,
         bazarId: values.bazar?.id,
         salePointId: values.salePoint?.id,
         dealerId: values.dealer?.id,
         comment: values.comment
      };

      checkPhoneNumber();

      async function create() {
         if (contragentId) {
            const resp = await PUT("/services/admin/api/contragent", obj);
            if (resp?.success) {
               history.push("/admin/contragent");
            }
         } else {
            const resp = await POST("/services/admin/api/contragent", obj);
            if (resp?.success) {
               history.push("/admin/contragent");
               toast.success("Успешно добавлен");
            }
         }
      }

      if (!phoneErr) {
         create();
      }
   }

   async function checkPhoneNumber() {
      setPhoneErr(null);
      const res = await POST(`/services/admin/api/contragent/phone-check`, {
         phone: getValues.phone,
      });

      if (res?.response?.status === 400) {
         setPhoneErr(res.response?.data?.message);
      }
      if (res?.success) {
         setPhoneErr(null);
      } else {
         setPhoneErr(" ");
      }
   }

   function selectType(selectedOption) {
      if (selectedOption !== type) {
         setType(selectedOption);
      }
   }

   function selectRegion(selectedOption) {
      if (selectedOption !== region) {
         setRegion(selectedOption);
         getCities(selectedOption.id);
         setCity(null);
         setBazar({});
         setBazars([]);
         setSalePoint({});
         setSale([]);
      }
   }

   function selectCity(selectedOption) {
      if (selectedOption !== city) {
         setCity(selectedOption);
         getBazars(selectedOption.id);
         setBazar(null);
         setBazars([]);
         setSalePoint({});
         setSale([]);
      }
   }

   function selectBazar(selectedOption) {
      if (selectedOption !== bazar) {
         setBazar(selectedOption);
         getSale(selectedOption.id);
         setSalePoint(null);
         setSale([]);
      }
   }

   function selectSalepoint(selectedOption) {
      if (selectedOption !== salePoint) {
         setSalePoint(selectedOption);
      }
   }

   function selectDealer(selectedOption) {
      if (selectedOption !== dealer) {
         setDealer(selectedOption);
      }
   }

   function selectMain(selectedOption) {
      if (selectedOption !== parent) {
         setParent(selectedOption);
      }
   }

   async function getData() {
      const response = await GET(
         "/services/admin/api/contragent/" + contragentId
      );
      if (response) {
         const {
            approved,
            id,
            name,
            phone,
            description,
            smartupName,
            parentId,
            comment,
            // parentName,
            regionHelperDTO,
            cityHelperDTO,
            bazarHelperDTO,
            salePointHelperDTO,
            dealerId,
            // dealerName,
         } = response;
         setValue("name", name);
         setValue("phone", "+" + phone);
         setValue("comment", comment);
         setValue("smartupName", smartupName);
         setValue("description", description);
         setData((prev) => ({
            ...prev,
            approved,
            id,
         }));

         setType(parentId === 0 ? typeOptions[0] : typeOptions[1]);

         const getRegions = await GET("/services/admin/api/region-helper/1");
         if (getRegions) {
            setRegions(getRegions);
            setRegion(
               getRegions.find((item) => item?.id === regionHelperDTO?.id)
            );
         }

         const getCities = await GET(
            `/services/admin/api/city-helper/${regionHelperDTO?.id}`
         );
         if (getCities) {
            setCities(getCities);
            setCity(getCities.find((item) => item?.id === cityHelperDTO?.id));
         }

         const getBazars = await GET(
            `/services/admin/api/bazar-helper/${cityHelperDTO?.id}`
         );
         if (getBazars) {
            setBazars(getBazars);
            setBazar(getBazars.find((item) => item?.id === bazarHelperDTO?.id));
         }

         const getSale = await GET(
            `/services/admin/api/salepoint-helper/${bazarHelperDTO?.id}`
         );
         if (getSale) {
            setSale(getSale);
            setSalePoint(
               getSale.find((item) => item?.id === salePointHelperDTO?.id)
            );
         }

         const getDealers = await GET(`/services/admin/api/dealer-helper`);
         if (getDealers) {
            setDealers(getDealers);
            setDealer(getDealers.find((item) => item.id === dealerId));
         }

         const getParents = await GET(
            `/services/admin/api/contragent-parent-helper`
         );
         if (getParents) {
            setParents(getParents);
            setParent(getParents.find((item) => item?.id === parentId));
         }
      }
   }

   async function getRegions() {
      const response = await GET("/services/admin/api/region-helper/1");
      if (response?.length > 0) {
         setRegions(response);
      }
   }

   async function getCities(id) {
      const response = await GET(`/services/admin/api/city-helper/${id || 0}`);
      if (response?.length > 0) {
         setCities(response);
      }
   }

   async function getBazars(id) {
      const response = await GET(`/services/admin/api/bazar-helper/${id || 0}`);
      if (response.length > 0) {
         setBazars(response);
      }
   }

   async function getSale(id) {
      const response = await GET(
         `/services/admin/api/salepoint-helper/${id || 0}`
      );
      if (response?.length > 0) {
         setSale(response);
      }
   }

   async function getDealers() {
      const response = await GET(`/services/admin/api/dealer-helper`);
      if (response?.length > 0) {
         setDealers(response);
      }
   }

   async function getParents() {
      const response = await GET(
         `/services/admin/api/contragent-parent-helper`
      );
      if (response?.length > 0) {
         setParents(response);
      }
   }

   useEffect(() => {
      if (contragentId) {
         getData();
      } else {
         getRegions();
         getCities();
         getBazars();
         getSale();
         getDealers();
         getParents();
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className="mb-1 mx-3 ">
         <h3 className="mb-3 mt-1">
            {contragentId ? "Изменить" : "Добавить контрагент"}
         </h3>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="admin-card p-4 overflow-y-auto">
               <div className="row mb-1">
                  <div>
                     <div>
                        <div className="d-flex align-items-center mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end"
                           >
                              ФИО
                           </label>
                           <div>
                              <input
                                  {...register("name", {required: true})}
                                  className="input-control"
                                  style={{width: "300px"}}
                              />
                              {errors.name && (
                                  <div className="text-danger mt-1 fz12">
                                     Обязательное поле
                                  </div>
                              )}
                           </div>
                        </div>
                        <div className="d-flex align-items-center mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end text-nowrap"
                           >
                              Номер телефона
                           </label>
                           <div>
                              <PhoneInput
                                  name="phone"
                                  control={control}
                                  rules={{
                                     required: true,
                                     validate: isValidPhoneNumber,
                                  }}
                                  international
                                  withCountryCallingCode
                                  country="UZ"
                                  style={{width: "300px"}}
                                  className="input-control"
                              />

                              {errors.phone && (
                                  <div className="text-danger mt-1 fz12">
                                     "Заполните полностью и правильно"
                                  </div>
                              )}

                              {phoneErr && (
                                  <p className="text-danger m-0">{phoneErr}</p>
                              )}
                           </div>
                        </div>
                        <div className="d-flex align-items-center mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end text-nowrap"
                           >
                              Smartup имя
                           </label>
                           <div>
                              <input
                                  {...register("smartupName", {
                                     required: true,
                                  })}
                                  className="input-control"
                                  style={{width: "300px"}}
                              />
                              {errors.smartupName && (
                                  <div className="text-danger mt-1 fz12">
                                     Обязательное поле
                                  </div>
                              )}
                           </div>
                        </div>
                        <div className="d-flex mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end"
                           >
                              Комментария
                           </label>
                           <div>
                              <textarea
                                  {...register("comment", {
                                     required: true,
                                  })}
                                  className="input-control"
                                  style={{width: "300px", resize: "none"}}
                              />
                              {errors.comment && (
                                  <div className="text-danger mt-1 fz12">
                                     Обязательное поле
                                  </div>
                              )}
                           </div>
                        </div>
                        <div className="d-flex mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end"
                           >
                              Описание
                           </label>
                           <div>
                              <textarea
                                  {...register("description", {
                                     required: true,
                                  })}
                                  className="input-control"
                                  style={{width: "300px", resize: "none"}}
                              />
                              {errors.description && (
                                  <div className="text-danger mt-1 fz12">
                                     Обязательное поле
                                  </div>
                              )}
                           </div>
                        </div>
                     </div>

                     <div>
                        <div className="d-flex align-items-center mb-2 gap-2">
                           <label
                               style={{width: "120px"}}
                               className="color-grey text-end text-nowrap"
                           >
                              Выберите тип
                           </label>
                           <div style={{width: "300px"}}>
                              <Select
                                  {...register("type", {
                                     required: true,
                                     value: type,
                                  })}
                                  value={type}
                                  placeholder="Выберите тип"
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  options={typeOptions}
                                  onChange={selectType}
                              />
                              {errors.type && (
                                 <div className="text-danger mt-1 fz12">
                                    Обязательное поле
                                 </div>
                              )}
                           </div>
                        </div>

                        {type.value ? (
                           <div>
                              <div className="mb-2 d-flex align-items-center gap-2">
                                 <label
                                    style={{ width: "120px" }}
                                    className="color-grey text-end text-nowrap"
                                 >
                                    Регион
                                 </label>
                                 <div
                                    style={{ width: "300px", fontSize: "12px" }}
                                 >
                                    <Select
                                       {...register("region", {
                                          required: true,
                                          value: region,
                                       })}
                                       value={region}
                                       placeholder="Выберите регион"
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       options={regions}
                                       onChange={selectRegion}
                                    />
                                    {errors.region && (
                                       <div className="text-danger mt-1 fz12">
                                          Обязательное поле
                                       </div>
                                    )}
                                 </div>
                              </div>
                              <div className="mb-2 d-flex align-items-center gap-2">
                                 <label
                                    style={{ width: "120px" }}
                                    className="color-grey text-end text-nowrap"
                                 >
                                    Город район
                                 </label>
                                 <div style={{ width: "300px" }}>
                                    <Select
                                       {...register("city", {
                                          required: true,
                                          value: city,
                                       })}
                                       value={city}
                                       placeholder="Выберите город(район)"
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       options={cities}
                                       onChange={selectCity}
                                    />
                                    {errors.city && (
                                       <div className="text-danger mt-1 fz12">
                                          Обязательное поле
                                       </div>
                                    )}
                                 </div>
                              </div>
                              <div className="mb-2 d-flex align-items-center gap-2">
                                 <label
                                    style={{ width: "120px" }}
                                    className="color-grey text-end text-nowrap"
                                 >
                                    Базар
                                 </label>
                                 <div style={{ width: "300px" }}>
                                    <Select
                                       {...register("bazar", {
                                          required: true,
                                          value: bazar,
                                       })}
                                       value={bazar}
                                       placeholder="Выберите базар"
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       options={bazars}
                                       onChange={selectBazar}
                                    />
                                    {errors.bazar && (
                                       <div className="text-danger mt-1 fz12">
                                          Обязательное поле
                                       </div>
                                    )}
                                 </div>
                              </div>

                              <div className="mb-2 d-flex align-items-center gap-2">
                                 <label
                                    style={{ width: "120px" }}
                                    className="color-grey text-end text-nowrap"
                                 >
                                    Торговая точка
                                 </label>
                                 <div style={{ width: "300px" }}>
                                    <Select
                                       {...register("salePoint", {
                                          required: true,
                                          value: salePoint,
                                       })}
                                       value={salePoint}
                                       placeholder="Выберите торговую точку"
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       options={sale}
                                       onChange={selectSalepoint}
                                    />
                                    {errors.salePoint && (
                                       <div className="text-danger mt-1 fz12">
                                          Обязательное поле
                                       </div>
                                    )}
                                 </div>
                              </div>

                              <div className="mb-2 d-flex align-items-center gap-2">
                                 <label
                                    style={{ width: "120px" }}
                                    className="color-grey text-end text-nowrap"
                                 >
                                    Дилер
                                 </label>
                                 <div style={{ width: "300px" }}>
                                    <Select
                                       {...register("dealer", {
                                          required: true,
                                          value: dealer,
                                       })}
                                       value={dealer}
                                       placeholder="Выберите дилера"
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       options={dealers}
                                       onChange={selectDealer}
                                    />
                                    {errors.dealer && (
                                       <div className="text-danger mt-1 fz12">
                                          Обязательное поле
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </div>
                        ) : (
                           <div className="mb-2 d-flex align-items-center gap-2">
                              <label
                                 style={{ width: "120px" }}
                                 className="color-grey text-end text-nowrap"
                              >
                                 Выберите главного
                              </label>
                              <div style={{ width: "300px" }}>
                                 <Select
                                    {...register("parent", {
                                       required: true,
                                       value: parent,
                                    })}
                                    value={parent}
                                    placeholder="Выберите главного"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    options={parents}
                                    onChange={selectMain}
                                 />
                                 {errors.parent && (
                                    <div className="text-danger mt-1 fz12">
                                       Обязательное поле
                                    </div>
                                 )}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>

               <div className="d-flex justify-content-end">
                  <Link to="/admin/contragent" className="mr-3">
                     <button className="btn btn-outline-purple d-flex align-items-center">
                        <XCircle size={14} className="mr-2" />
                        Отмена
                     </button>
                  </Link>
                  <button
                     type="submit"
                     className="btn btn-purple d-flex align-items-center"
                  >
                     <Save size={14} className="mr-2" />
                     Сохранить
                  </button>
               </div>
            </div>
         </form>
      </div>
   );
}

export default ContragentAddUpdate;
