import React, {useEffect, useState} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { XCircle, Save } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {GET, PGET, POST, PUT} from "../../../api/adminApi";
import Select from "react-select";

function ModelsAddUpdate() {
    const { id } = useParams();
    const history = useHistory();
    const [brand, setBrand] = useState(null);
    const [brands, setBrands] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();


    async function getBrands() {
        const response = await GET("/services/admin/api/brand-helper");
        if (response?.length > 0) {
            setBrands(response);
        }
    }

    function selectBrand(selectedOption) {
        if (selectedOption !== brand) {
            setBrand(selectedOption);
        }
    }

    async function onSubmit(data) {

        const obj = {
            ...data,
            name: data.name,
            brandId: brand ? brand.id : null,
        };

        if (id) {
            const response = await PUT("/services/admin/api/model", {
                id: +id,
                brandId: brand ? brand.id : null,
            });

            if (response?.success) {
                history.push("/admin/models");
            }
        } else {
            const response = await POST("/services/admin/api/model", obj);

            if (response?.success === true) {
                history.push("/admin/models");
                toast.success("Успешно");
            }
        }
    }

    async function getObject() {
        const res = await PGET("/services/admin/api/model/" + id);
        setValue("name", res?.data?.name);
        setValue("description", res?.data?.description);

        const getBrands = await GET("/services/admin/api/brand-helper");
        if (getBrands) {
            setBrands(getBrands);
            setBrand(
                getBrands.find((item) => item?.id === item?.id)
            );
        }

    }

    useEffect(() => {
        if (id) {
            getObject();
        } else {
            getBrands()
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card py-4">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    action=""
                >
                    <div className="d-flex">
                        <div className="" style={{ width: "500px" }}>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название</label>
                                    </div>
                                    <input
                                        {...register("name", { required: true })}
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.name && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="mb-2 d-flex align-items-center gap-2">
                                    <label
                                        style={{width: "120px"}}
                                        className="color-grey text-end text-nowrap"
                                    >
                                        Бренд
                                    </label>
                                    <div style={{width: "300px", fontSize: "12px"}}>
                                        <Select
                                            {...register("brandId", {
                                                value: brand,
                                            })}
                                            value={brand}
                                            placeholder="Выберите тип"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            options={brands}
                                            onChange={selectBrand}
                                        />
                                        {errors.brandId && (
                                            <div className="text-danger mt-1 fz12">
                                                Обязательное поле
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to="/admin/models" className="mr-3">
                            <button className="btn btn-outline-purple d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            brand="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ModelsAddUpdate;
