import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { XCircle, Save } from 'react-feather';

import { POST, GET, FILE, PUT } from '../../../api/adminApi'

function CuisinesAddUpdate() {
	let { id } = useParams();
	let history = useHistory();
	const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
		nameRu: '',
		nameUz: '',
		descriptionRu: '',
		descriptionUz: '',
		imageUrl: '',
		sorting: 1
	})
	const [data, setData] = useState({
		nameRu: '',
		nameUz: '',
		descriptionRu: '',
		descriptionUz: '',
		imageUrl: '',
		sorting: '1'
	})

	async function create(data) {
		var response;
		if (id) {
			response = await PUT('/services/admin/api/cuisines', data)
		} else {
			response = await POST('/services/admin/api/cuisines', data)
		}
		console.log(response);
		if (response) {
			history.push('/admin/directory/cuisines')
		}

	}

	async function getProduct() {
		const response = await GET('/services/admin/api/cuisines/' + id, data)
		setData(response)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
	}

	async function getImageUrl(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			await FILE('/services/admin/api/cuisines-image-upload', formData).then(response => {
				setData({ ...data, imageUrl: response.url })
				setValue('imageUrl', response.url)
			}
			).catch(error => console.log(error))
			console.log(getValues('imageUrl'));
		}
	}

	useEffect(() => {
		if (id) {
			getProduct()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4 overflow-y-auto">
				<form onSubmit={handleSubmit(create)}>
					<div className="row">
						<div className="col-md-6 mb-3">
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Название (рус)</label></div>
									<input {...register("nameRu", { required: true })} type="text" placeholder="Название" className="input-control" />
								</div>
								{errors.nameRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Название (узб)</label></div>
									<input {...register("nameUz", { required: true })} type="text" placeholder="Название" className="input-control" />
								</div>
								{errors.nameUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание (рус)</label></div>
									<input {...register("descriptionRu", { required: true })} type="text" placeholder="Описание" className="input-control" />
								</div>
								{errors.descriptionRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Описание (рус)</label></div>
									<input {...register("descriptionUz", { required: true })} type="text" placeholder="Описание" className="input-control" />
								</div>
								{errors.descriptionUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Сорт</label></div>
									<input {...register("sorting", { required: true })} type="number" className="input-control" />
								</div>
								{errors.sorting?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
						</div>
						<div className="col-md-6 mb-3 d-flex flex-column align-items-center color-grey">

							<div className="admin-field">
								<div className="d-flex w-100">
									<div className="admin-label mr-md-3"><label className="mb-0">Логотип бренда</label></div>
									<div className="position-relative">
										<div className="image-block" style={{ width: '200px', minWidth: '100px', minHeight: '200px', border: '1px dashed #D4D3D6' }}>Перетащите сюда файлы для загрузки</div>
										<input onChange={(e) => getImageUrl(e)} type="file" placeholder="Фамилия" style={{ width: '200px', minWidth: '100px', minHeight: '200px', opacity: '0', top: 0, zIndex: 3 }} className="position-absolute cursor border border-radius-3 box outline-none color-grey" />
										{/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
										{
											data.imageUrl &&
											<div className="position-absolute cursor  border-radius-3" style={{ width: '200px', minWidth: '100px', minHeight: '200px', top: 0, zIndex: 2 }}>
												<img className="w-100 h-100 background-white border box-shadow" src={'https://colibri24.uz' + (data.imageUrl)} alt="" />
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-postion d-flex">
						<Link to='/admin/cuisines' className="mr-3"><button className="btn btn-outline-purple d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </button></Link>
						<button className="btn btn-purple d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default CuisinesAddUpdate
