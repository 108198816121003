import React, {useEffect, useState} from "react";
import {PGET} from "../../../api/adminApi";

export default function TopInstallations() {
    const [items, setItems] = useState([]);
    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-topten-masters/", true, {
        });
        setItems(data.data);
    }
    useEffect(() => {
        getItems()
    },[])
    return (
        <div className="pb-4 pt-2" style={{minHeight: 400, width: '100%'}}>
            <div style={{ overflowX: "auto" }}>
                <table className="table background-white rounded br-5">
                    <thead>
                    <tr className="backgroung-grey">
                        <th style={{width: "30px"}} className="text-center">
                            №
                        </th>
                        <th className="text-start">ФИО</th>
                        <th className="text-start">Регион</th>
                        <th className="text-center">Кол-во установок</th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr key={item.id}>
                            <td className="text-center">
                                {index + 1}
                            </td>
                            <td className="text-start">{item.mountedMasterName}</td>
                            <td className="text-start">{item.regionName}</td>
                            <td className="text-center">
                                {item.mountedCount}
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
        </div>)
}

