import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {HelpCircle, CheckSquare, Search, Trash2} from "react-feather";
import { toast } from "react-toastify";
import Select from "react-select";

import Logo from "../../../assets/logo2.png";
import { PGET, POST } from "../../../api/adminApi";
import ChangeStatus from "./ChangeStatus";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import RequestMasterDeleteModal from "../RequestMasters/RequestMasterDeleteModal";
import RequestSalesDeleteModal from "./RequestSaleDeleteModal";

function RequestFromSellers() {
   const [items, setItems] = useState(null);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
      +localStorage.getItem("currentPageRequestFromSellers") || 0
   ); //for numeration and saving currentPage after refresh
   const [selectedUser, setSelectedUser] = useState(null);
   const [code, setCode] = useState(""); // checkCode input
   const [statusOptions, setStatusOptions] = useState([{ id: 0, name: "Все" }]);
   const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
   const [modal, setModal] = useState({ show: false, user: null });
   const [changeStatusOptions, setChangeStatusOptions] = useState([]);
   const [deleteModal, setDeleteModal] = useState({
      show: false,
      master: null,
   });
   const selectStatus = (selectedOpt) => {
      if (statusOpt !== selectedOpt) {
         setStatusOpt(selectedOpt);
         setCurrentPageToZero();
      }
   };

   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };

   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPageRequestFromSellers", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected); //for numeration
      localStorage.setItem("currentPageRequestFromSellers", data.selected); //for saving currentPage after refresh
   }

   async function getItems() {
      const data = await PGET(
         "/services/admin/api/request/sales-pageList",
         true,
         {
            statusId: statusOpt.id,
            page: currentPage,
            size: 15,
            search: value,
         }
      );
      setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
      setItems(data?.data);
      setCode("");
   }

   async function handleCheckCodeInput(e) {
      e.preventDefault();
      const response = await POST(
         "/services/admin/api/request/sales/checkCode",
         {
            code,
            requestCode: selectedUser.id,
         }
      );

      toast.info(response?.message);
      getItems();
   }

   useEffect(() => {
      getItems();
   }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      setSelectedUser(
         (prev) =>
            items &&
            (prev
               ? items.find((item) => item.id === prev.id) === undefined
                  ? items[0]
                  : items.find((item) => item.id === prev.id)
               : items[0])
      );
   }, [items]);

   useEffect(() => {
      async function getStatusHelper() {
         const data = await PGET("/services/admin/api/request/status-helper");
         if (data) {
            setStatusOptions([statusOptions[0], ...data.data]);
         }
      }
      getStatusHelper();
      async function getChangeStatusOptions() {
         const res = await PGET(
            "/services/admin/api/request/status-helper-change"
         );
         if (res) {
            setChangeStatusOptions(res.data);
         }
      }
      getChangeStatusOptions();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div style={{ background: "#eef1ff", marginTop: "-0.4rem" }}>
         <div className="fz18 p-3 pl-3 d-flex align-items-center">
            <HelpCircle className="mr-2" color="#5C3EBA" />{" "}
            <span>Запросы от продавцов</span>
         </div>

         <div
            className="m-3 gap-3"
            style={{ display: "grid", gridTemplateColumns: "auto 33%" }}
         >
            <div
               className="d-flex justify-content-end w-100"
               style={{ width: "150px" }}
            >
               <Select
                  className="w-30"
                  value={statusOpt}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={statusOptions}
                  onChange={selectStatus}
               />
            </div>

            <div className="position-relative w-100">
               <Search
                  size={14}
                  color="#9D9BA3"
                  className="input-search-icon"
               />
               <DebounceInput
                  minLength={3}
                  debounceTimeout={400}
                  value={value}
                  onChange={handleSearchInput}
                  type="text"
                  className="input-search box-shadow br-5 w-100"
                  size={24}
                  placeholder="Поиск"
               />
            </div>

            <table className="table background-white rounded box-shadow br-5 align-self-start">
               <thead>
                  <tr className="backgroung-grey">
                     <th className="text-center">№</th>
                     <th className="text-start">Время получения</th>
                     <th className="text-start">Номер запроса</th>
                     <th className="text-start">Номер телефона</th>
                     <th className="text-center">Статус</th>
                     <th className="text-center">Балл</th>
                     <th className="text-center"></th>
                     <th className="text-center"></th>
                  </tr>
               </thead>

               <tbody style={{ overflowX: "auto" }}>
                  {items?.map((user, idx) => {
                     const {
                        id,
                        receivedDate,
                        phone,
                        requestStatus,
                        requestStatusId,
                        earnedPoint,
                        // filePath,
                        // contragentName,
                        // regionHelperDTO,
                        // cityHelperDTO,
                        // bazarHelperDTO,
                        // salePointHelperDTO,
                     } = user;
                     return (
                        <tr
                           onClick={() => {
                              setSelectedUser(user);
                           }}
                           role="button"
                           className={
                              id === selectedUser?.id ? "box-shadow" : ""
                           }
                           style={{
                              background: `${
                                 id === selectedUser?.id ? "#eef1ff" : "white"
                              }`,
                           }}
                           key={id}
                        >
                           <td className="text-center pointer">
                              {currentPage === 0
                                 ? idx + 1
                                 : // : currentPage * 10 + idx + 1} // for 10 items per page
                                   // for 15 items per page
                                   currentPage * 10 + idx + 1 + 5 * currentPage}
                           </td>
                           <td className="text-start">
                              {new Date(receivedDate).toLocaleString()}
                           </td>
                           <td className="text-start">{id}</td>
                           <td className="text-start">{phone}</td>
                           <td className={`text-start w-10`}>
                              <b
                                 style={{
                                    whiteSpace: "nowrap",
                                 }}
                                 className={`p-1 text-white rounded font-weight-500 d-block ${
                                    requestStatusId === 1
                                       ? "bg-warning text-black"
                                       : requestStatusId === 2
                                       ? "bg-success"
                                       : requestStatusId === 3
                                       ? "bg-danger"
                                       : "bg-primary"
                                 }`}
                              >
                                 {requestStatus}
                              </b>
                           </td>
                           <td className="text-center">{earnedPoint}</td>
                           <td className="text-center">
                              <button
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    setModal({ show: true, user });
                                 }}
                                 className="btn btn-table box-shadow br-5"
                                 style={{
                                    backgroundColor: "#F4F4F5",
                                 }}
                              >
                                 <CheckSquare color={"#5C3EBA"} size={16} />
                              </button>
                              <button
                                  onClick={(e) => {
                                     e.stopPropagation();
                                     setDeleteModal({
                                        show: true,
                                        master: user,
                                     });
                                  }}
                                  className="btn btn-table mr-0 box-shadow br-5"
                                  style={{ backgroundColor: "#F4F4F5" }}
                              >
                                 <Trash2 color={"#E63950"} size={16} />
                              </button>
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </table>

            <div className="h-100">
               <table className="table background-white rounded box-shadow br-5">
                  <tbody>
                  <tr>
                     <th>Контрагент:</th>
                     <td className="text-end">
                        {selectedUser?.contragentName}
                     </td>
                  </tr>
                  <tr>
                     <th>Регион:</th>
                     <td className="text-end">
                        {selectedUser?.regionHelperDTO?.name}
                     </td>
                  </tr>
                  <tr>
                     <th>Город/Район:</th>
                     <td className="text-end">
                        {selectedUser?.cityHelperDTO?.name}
                     </td>
                  </tr>
                  <tr>
                     <th>Базар:</th>
                     <td className="text-end">
                        {selectedUser?.bazarHelperDTO?.name}
                     </td>
                  </tr>
                  <tr>
                     <th>Торговая точка:</th>
                     <td className="text-end">
                        {selectedUser?.salePointHelperDTO?.name}
                     </td>
                  </tr>
                  </tbody>
               </table>

               <table className="table background-white rounded box-shadow br-5">
                  <tbody>
                  <tr>
                     <th>Заметка:</th>
                     <td className="text-end">{selectedUser?.contragentComment}</td>
                  </tr>
                  </tbody>
               </table>

               {selectedUser?.requestStatusId === 4 && (
                   <table className="table background-white rounded box-shadow br-5">
                      <tbody>
                      <tr>
                         <th>Продан контрагентом:</th>
                         <td className="text-end">
                            {selectedUser?.soldUserName}
                         </td>
                      </tr>
                      <tr>
                         <th>Запрос продажи:</th>
                         <td className="text-end">
                            {selectedUser?.soldRequestId}
                         </td>
                      </tr>
                      </tbody>
                   </table>
               )}

               <div className="rounded box-shadow br-5 background-white d-flex items-center justify-content-center">
                  <TransformWrapper>
                     <TransformComponent>
                        <img
                            style={{
                               width: "100%",
                               maxHeight: "550px",
                               minHeight: "330px",
                            }}
                            src={`${
                                selectedUser?.id
                                    ? "https://bo.airfelsales.uz/" +
                                    selectedUser.filePath
                                    : Logo
                            }`}
                            alt="img"
                        />
                     </TransformComponent>
                  </TransformWrapper>
               </div>

               {selectedUser?.requestStatusId !== 2 && (
                   <form
                       onSubmit={handleCheckCodeInput}
                       className="input-group my-3 rounded box-shadow br-5"
                   >
                      <input
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Введите код"
                          aria-describedby="button-addon2"
                          required
                      />
                      <button
                          className="btn btn-success"
                          type="submit"
                          id="button-addon2"
                      >
                         Проверить
                      </button>
                   </form>
               )}
            </div>
         </div>

         {pageCount > 1 && (
             <ReactPaginate
                 forcePage={currentPage}
                 breakLabel={"..."}
                 breakClassName={"break-me"}
                 pageCount={pageCount}
                 marginPagesDisplayed={1}
                 pageRangeDisplayed={1}
                 onPageChange={paginate}
                 containerClassName={"pagination"}
               activeClassName={"active"}
               pageClassName={"page-item box-shadow br-5"}
            />
         )}
         {deleteModal.master && (
             <RequestSalesDeleteModal
                 deleteModal={deleteModal}
                 setDeleteModal={setDeleteModal}
                 getItems={getItems}
             />
         )}
         {modal.user && (
            <ChangeStatus
               modal={modal}
               setModal={setModal}
               getItems={getItems}
               statusOptions={changeStatusOptions}
            />
         )}
      </div>
   );
}

export default RequestFromSellers;
