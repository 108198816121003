import React from "react";
import Modal from "react-bootstrap/Modal";
import { Save, XCircle } from "react-feather";
import { useForm } from "react-hook-form";
import { PUT } from "../../../api/adminApi";

export default function CodeUnassign({
   modal: { show, masterId },
   setModal,
   getItems,
}) {
   const {
      register,
      handleSubmit,
      formState: { errors },
      clearErrors,
   } = useForm();

   function onHide() {
      setModal({ show: false, masterId: null });
      clearErrors();
   }

   async function unassign() {
      const res = await PUT("/services/admin/api/codes/unassign-object", {
         id: masterId,
      });
      if (res && res.success === true) {
         getItems();
         onHide();
      }
   }

   return (
      <Modal
         aria-labelledby="contained-modal-title-vcenter"
         centered
         show={show}
         onHide={onHide}
      >
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               Вы действительно хотите отвязать?
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
               <div className="d-flex justify-content-end gap-4">
                  <button
                     type="button"
                     onClick={onHide}
                     className="btn btn-outline-purple d-flex align-items-center"
                  >
                     <XCircle size={14} className="mr-2" />
                     Отмена
                  </button>

                  <button
                      onClick={unassign}
                     type="submit"
                     className="btn btn-purple d-flex align-items-center"
                  >
                     <Save size={14} className="mr-2" />
                     Отвязать{" "}
                  </button>
               </div>
         </Modal.Body>
      </Modal>
   );
}
