import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { XCircle, Save } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { POST, PUT, PGET } from "../../../api/adminApi";

function DealerAddUpdate() {
   const { id: dealerId } = useParams();
   const history = useHistory();
   const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
   } = useForm();

   async function onSubmit(data) {
      if (dealerId) {
         const response = await PUT("/services/admin/api/dealer", {
            id: +dealerId,
            ...data,
         });

         if (response?.success) {
            history.push("/admin/dealer");
         }
      } else {
         const response = await POST("/services/admin/api/dealer", data);

         if (response?.success) {
            history.push("/admin/dealer");
            toast.success("Успешно");
         }
      }
   }

   async function getDealer() {
      const res = await PGET("/services/admin/api/dealer/" + dealerId);
      setValue("name", res?.data?.name);
      setValue("description", res?.data?.description);
   }

   useEffect(() => {
      if (dealerId) {
         getDealer();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="mb-2 mx-3 ">
         <h3 className="mb-3 mt-1">Добавить новый</h3>
         <div className="admin-card py-4">
            <form
               onSubmit={handleSubmit(onSubmit)}
               autoComplete="off"
               action=""
            >
               <div className="d-flex">
                  <div className="" style={{ width: "500px" }}>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">ФИО</label>
                           </div>
                           <input
                              {...register("name", { required: true })}
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.name && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Описание</label>
                           </div>
                           <textarea
                              {...register("description", { required: true })}
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.description && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               <div className="btn-postion d-flex">
                  <Link to="/admin/dealer" className="mr-3">
                     <button className="btn btn-outline-purple d-flex align-items-center">
                        <XCircle size={14} className="mr-2" />
                        Отмена
                     </button>
                  </Link>
                  <button
                     type="submit"
                     className="btn btn-purple d-flex align-items-center"
                  >
                     <Save size={14} className="mr-2" />
                     Сохранить{" "}
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
}

export default DealerAddUpdate;
