import React, {useEffect, useState} from "react";
import {Save, XCircle} from "react-feather";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {GET, POST} from "../../../api/adminApi";
import {log10} from "chart.js/helpers";

export default function PrizeModal({
                                       modal: {show, user},
                                       setModal,
                                       getItems,
                                   }) {
    const [prizeOptions, setPrizeOptions] = useState([]);
    const [prizeOption, setPrizeOption] = useState(prizeOptions[0]);
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        clearErrors,
    } = useForm();

    async function getPrizeHelper() {
        const response = await GET(`/services/admin/api/prize-helper-filtered/${user?.totalPoint}`);

        if (response.length > 0) {
            setPrizeOptions(response);
        }
    }

    async function changeStatus() {
        const response = await POST(
            "/services/admin/api/contragent-point-request",
            {
                contragentId: user.id,
                prizeId: prizeOption.id,
            }
        );
        if (response && response.success === true) {
            getItems();
            onHide();
        }
    }

    console.log(prizeOption)
    const selectPrize = (selectedOpt) => {
        if (selectedOpt !== prizeOption) {
            setPrizeOption(selectedOpt);
        }
    };

    function onHide() {
        setModal({show: false, user: null});
        setPrizeOption(null);
        setValue("comment", "");
        clearErrors();
    }

    useEffect(() => {
        getPrizeHelper()
    }, []);
    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Обмен на призы
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100 mb-4">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">ФИО</label>
                                    </div>
                                    <div className="input-control w-75 opacity-50">
                                        {user?.name}
                                    </div>
                                </div>
                                <div className="d-flex w-100 mb-4">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Общие баллы</label>
                                    </div>
                                    <div className="input-control w-75 opacity-50">
                                        {user?.totalPoint}
                                    </div>
                                </div>
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25 text-black">
                                        <label className="mb-0">Выберите приз</label>
                                    </div>
                                    <Select
                                        value={prizeOption}
                                        placeholder="Выберите приз"
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.id}
                                        options={prizeOptions}
                                        onChange={selectPrize}
                                        className="w-75 text-black"
                                    />
                                </div>
                                {errors.status && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-4 mt-5">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            disabled={prizeOptions.length <= 0}
                            type="submit"
                            className={`btn btn-purple d-flex align-items-center ${prizeOptions.length <= 0 ? 'cursor-not-allowed' : ''}`}
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
