import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { XCircle, Save } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { PGET, POST, PUT } from "../../../api/adminApi";

function ObjectAddUpdate() {
   const { id } = useParams();
   const history = useHistory();
   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
   } = useForm();

   async function onSubmit(data) {
      if (id) {
         const response = await PUT("/services/admin/api/object", {
            id: +id,
            ...data,
         });

         if (response?.success) {
            history.push("/admin/object");
         }
      } else {
         const response = await POST("/services/admin/api/object", data);

         if (response?.success === true) {
            history.push("/admin/object");
            toast.success("Успешно");
         }
      }
   }

   async function getObject() {
      const res = await PGET("/services/admin/api/object/" + id);
      setValue("name", res?.data?.name);
      setValue("description", res?.data?.description);
   }

   useEffect(() => {
      if (id) {
         getObject();
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className="mb-2 mx-3 ">
         <h3 className="mb-3 mt-1">Добавить новый</h3>
         <div className="admin-card py-4">
            <form
               onSubmit={handleSubmit(onSubmit)}
               autoComplete="off"
               action=""
            >
               <div className="d-flex">
                  <div className="" style={{ width: "500px" }}>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Название</label>
                           </div>
                           <input
                              {...register("name", { required: true })}
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.name && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                     <div className="admin-field">
                        <div className="d-flex w-100">
                           <div className="admin-label mr-md-3">
                              <label className="mb-0">Описание</label>
                           </div>
                           <textarea
                              {...register("description", { required: true })}
                              placeholder=""
                              className="input-control"
                           />
                        </div>
                        {errors.description && (
                           <div className="text-danger mt-1 fz12">
                              Обязательное поле
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               <div className="btn-postion d-flex">
                  <Link to="/admin/object" className="mr-3">
                     <button className="btn btn-outline-purple d-flex align-items-center">
                        <XCircle size={14} className="mr-2" />
                        Отмена
                     </button>
                  </Link>
                  <button
                     type="submit"
                     className="btn btn-purple d-flex align-items-center"
                  >
                     <Save size={14} className="mr-2" />
                     Сохранить{" "}
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
}

export default ObjectAddUpdate;
