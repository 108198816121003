import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { Edit3, Search, PlusCircle, Trash2, Truck } from "react-feather";

import { PGET, DELETE } from "../../../api/adminApi";

function Dealer() {
   const [items, setItems] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [value, setValue] = useState("");
   const [currentPage, setCurrentPage] = useState(
      +localStorage.getItem("currentPageDealers") || 0
   ); //for numeration and saving currentPage after refresh

   const handleSearchInput = (e) => {
      setValue(e.target.value);
      setCurrentPageToZero();
   };

   function setCurrentPageToZero() {
      setCurrentPage(0);
      localStorage.setItem("currentPageDealers", 0);
   }

   function paginate(data) {
      setCurrentPage(data.selected);
      localStorage.setItem("currentPageDealers", data.selected); //for saving currentPage after refresh
   }

   async function getItems() {
      const data = await PGET("/services/admin/api/dealer-pageList", true, {
         page: currentPage,
         size: 10,
         search: value,
      });
      setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
      setItems(data.data);
   }

   async function deleteItem(id) {
      await DELETE("/services/admin/api/dealer/" + id);
      getItems();
   }

   useEffect(() => {
      getItems();
   }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div style={{ background: "#eef1ff", marginTop: "-0.9rem" }}>
         <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
            <Truck className="mr-2" color="#5C3EBA" /> <span>Дилеры</span>
         </div>

         <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-between">
               <Link to="/admin/dealer/create">
                  <button className="btn btn-success d-flex align-items-center box-shadow">
                     <PlusCircle size={18} className="mr-1" />
                     <div>Добавить новый</div>
                  </button>
               </Link>

               <div className="position-relative">
                  <Search
                     size={14}
                     color="#9D9BA3"
                     className="input-search-icon"
                  />
                  <DebounceInput
                     minLength={3}
                     debounceTimeout={400}
                     value={value}
                     onChange={handleSearchInput}
                     type="text"
                     className="input-search box-shadow"
                     size={24}
                     placeholder="Поиск"
                  />
               </div>
            </div>
            <div style={{ overflowX: "auto" }}>
               <table className="table background-white box-shadow">
                  <thead>
                     <tr className="backgroung-grey">
                        <th style={{ width: "30px" }} className="text-center">
                           №
                        </th>
                        <th className="text-start">ФИО</th>
                        <th className="text-start">Описание</th>
                        <th className="text-center"></th>
                     </tr>
                  </thead>

                  <tbody style={{ overflowX: "auto" }}>
                     {items.map((item, idx) => {
                        return (
                           <tr key={item.id}>
                              <td className="text-center">
                                 {currentPage === 0
                                    ? idx + 1
                                    : currentPage * 10 + idx + 1}
                              </td>
                              <td className="text-start">{item.name}</td>
                              <td className="text-start">{item.description}</td>
                              <td>
                                 <div className="d-flex justify-content-center">
                                    <Link
                                       to={"/admin/dealer/update/" + item.id}
                                    >
                                       <button
                                          className="btn btn-table"
                                          style={{ backgroundColor: "#F4F4F5" }}
                                       >
                                          <Edit3 color={"#189ED3"} size={16} />
                                       </button>
                                    </Link>
                                    <button
                                       onClick={() => deleteItem(item.id)}
                                       className="btn btn-table mr-0"
                                       style={{ backgroundColor: "#F4F4F5" }}
                                    >
                                       <Trash2 color={"#E63950"} size={16} />
                                    </button>
                                 </div>
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            {pageCount > 1 && (
               <ReactPaginate
                  forcePage={currentPage}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={paginate}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item box-shadow"}
               />
            )}
         </div>
      </div>
   );
}

export default Dealer;
