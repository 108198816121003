import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import {
   Home,
   Book,
   ChevronRight,
   ChevronDown,
   User,
   Truck,
   Edit,
   Settings,
   LogOut,
   Users,
   UserCheck,
   HelpCircle,
   MapPin,
   Bold,
   ShoppingCart,
   Tool,
   UserPlus,
   Trello,
   Lock,
   Gift,
   FileText,
   List,
   DollarSign,
   RefreshCcw, Box, Bookmark, ShoppingBag, Briefcase,
} from "react-feather";
import { Image } from "react-bootstrap";

// import menu from './SidebarMenu'

function SideBar({ children }) {
   const location = useLocation();
   let history = useHistory();
   const [showSideBar, setShowSideBar] = useState(false);
   const [showSettings, setShowSettings] = useState(false);
   const [menu, setMenu] = useState([
      {
         icon: <Home className="mr-2" size={16} />,
         name: "Главная",
         path: "/admin/main",
         show: false,
         children: [],
      },
      {
         icon: <Users className="mr-2" size={16} />,
         name: "Участники",
         path: "/admin/",
         show: false,
         children: [
            {
               icon: <UserCheck className="mr-2" size={16} />,
               name: "Контрагенты",
               path: "/admin/contragent",
               show: false,
               children: [],
            },
            {
               icon: <UserPlus className="mr-2" size={16} />,
               name: "Мастеры",
               path: "/admin/master",
               show: false,
               children: [],
            },
            {
               icon: <User className="mr-2" size={16} />,
               name: "Пользователи",
               path: "/admin/user",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <HelpCircle className="mr-2" size={16} />,
         name: "Запросы",
         path: "/admin/",
         show: false,
         children: [
            {
               icon: <ShoppingCart className="mr-2" size={16} />,
               name: "Запросы от продавцов",
               path: "/admin/requestFromSellers",
               show: false,
               children: [],
            },
            {
               icon: <Tool className="mr-2" size={16} />,
               name: "Запросы от мастеров",
               path: "/admin/requestFromMasters",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <FileText className="mr-2" size={16} />,
         name: "Логи",
         path: "/admin/log/",
         show: false,
         children: [
            {
               icon: <List className="mr-2" size={16} />,
               name: "Статусы по запросам продавцов",
               path: "/admin/log/status-sale",
               show: false,
               children: [],
            },
            {
               icon: <List className="mr-2" size={16} />,
               name: "Статусы по запросам мастеров",
               path: "/admin/log/status-master",
               show: false,
               children: [],
            },
            {
               icon: <DollarSign className="mr-2" size={16} />,
               name: "Баллы продавцов",
               path: "/admin/log/point-sale",
               show: false,
               children: [],
            },
            {
               icon: <DollarSign className="mr-2" size={16} />,
               name: "Баллы мастеров",
               path: "/admin/log/point-master",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <FileText className="mr-2" size={16} />,
         name: "Отчёты",
         path: "/admin/",
         show: false,
         children: [
            {
               icon: <UserCheck className="mr-2" size={16} />,
               name: "По контрагентам",
               path: "/admin/report-contragent",
               show: false,
               children: [],
            },
            {
               icon: <UserPlus className="mr-2" size={16} />,
               name: "По мастерам",
               path: "/admin/report-master",
               show: false,
               children: [],
            },
            {
               icon: <UserPlus className="mr-2" size={16} />,
               name: "Отчеты по объектам",
               path: "/admin/report-objects",
               show: false,
               children: [],
            },
            {
               icon: <List className="mr-2" size={16} />,
               name: "Экспорт данных",
               path: "/admin/export-data",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <Lock className="mr-2" size={16} />,
         name: "Коды",
         path: "/admin/code",
         show: false,
         children: [],
      },
      {
         icon: <RefreshCcw className="mr-2" size={16} />,
         name: "Обмен",
         path: "/admin/",
         show: false,
         children: [
            {
               icon: <RefreshCcw className="mr-2" size={16} />,
               name: "Запросы на обмен",
               path: "/admin/request-exchange",
               show: false,
               children: [],
            },
            {
               icon: <RefreshCcw className="mr-2" size={16} />,
               name: "Обмен на призы",
               path: "/admin/exchange-prize",
               show: false,
               children: [],
            },
         ],
      },
      {
         icon: <Book className="mr-2" size={16} />,
         name: "Справочник",
         path: "/admin/info",
         show: false,
         children: [
            {
               name: "Продукты",
               icon: <ShoppingBag className="mr-2" size={16} />,
               path: "/admin/products",
               show: false,
               children: [],
            },
            {
               name: "Бренды",
               icon: <Bookmark className="mr-2" size={16} />,
               path: "/admin/brand",
               show: false,
               children: [],
            },
            {
               name: "Модели",
               icon: <Box className="mr-2" size={16} />,
               path: "/admin/models",
               show: false,
               children: [],
            },
            {
               name: "Бонусы",
               icon: <Briefcase className="mr-2" size={16} />,
               path: "/admin/bonus",
               show: false,
               children: [],
            },
            {
               name: "Менеджеры",
               icon: <UserPlus className="mr-2" size={16} />,
               path: "/admin/manager",
               show: false,
               children: [],
            },
            {
               name: "Объекты",
               icon: <Trello className="mr-2" size={16} />,
               path: "/admin/object",
               show: false,
               children: [],
            },
            {
               name: "Дилеры",
               icon: <Truck className="mr-2" size={16} />,
               path: "/admin/dealer",
               show: false,
               children: [],
            },
            {
               name: "Базары",
               icon: <Bold className="mr-2" size={16} />,
               path: "/admin/bazar",
            },
            {
               name: "Торговые точки",
               icon: <ShoppingCart className="mr-2" size={16} />,
               path: "/admin/salepoint",
            },
            {
               name: "Территории",
               icon: <MapPin className="mr-2" size={16} />,
               path: "/admin/territory",
            },
            {
               name: "Призы",
               icon: <Gift className="mr-2" size={16} />,
               path: "/admin/prize",
            },
         ],
      },
   ]);

   function signOut() {
      localStorage.removeItem("admin_tokenTime");
      localStorage.removeItem("admin_access_token");
      localStorage.removeItem("admin");
      history.push("/");
   }

   const showItem = (index, bool) => {
      let copy = [...menu];
      let copyItem = { ...menu[index] };
      copyItem.show = bool;
      copy[index] = copyItem;
      setMenu(copy);
   };

   useEffect(() => {
      for (let i = 0; i < menu.length; i++) {
         showItem(i, false);
         if (location.pathname.includes(menu[i].path)) {
            if (menu[i].breadrcumb !== undefined && menu[i].breadrcumb.length) {
               var copy = menu;
               copy[i]["breadrcumb"][2] = location.pathname;
               setMenu([...menu, copy]);
            }
            if (menu[i].children.length > 0) {
               showItem(i, true);
            }
         }
      }
   }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div>
         <div
            className="sidebar sidebar-fixed"
            style={showSideBar ? { marginLeft: "-256px" } : {}}
         >
            <Link className="sidebar-brand pl-3" to="/">
               <Image
                  src="/img/logo3.png"
                  className="bg-light"
                  alt=""
                  height={32}
               />
            </Link>
            <div className="sidebar-nav" style={{ paddingTop: "40px" }}>
               {menu.map((item, index) => {
                  return (
                     <React.Fragment key={item.name}>
                        {item.children.length ? (
                           <div
                              className={
                                 "sidebar-nav-item mx-2 mb-1 cursor " +
                                 (item.show ? "sidebar-nav-active" : "")
                              }
                           >
                              <div
                                 onClick={() => showItem(index, !item.show)}
                                 className="mx-3 px-2 py-3"
                              >
                                 <div className="d-flex justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center">
                                       {item.icon}
                                       <span className="fz13">{item.name}</span>
                                    </div>
                                    {item.children.length && item.show ? (
                                       <ChevronDown
                                          color={"#7050A4"}
                                          size={16}
                                       />
                                    ) : (
                                       <ChevronRight size={16} />
                                    )}
                                 </div>
                              </div>
                           </div>
                        ) : (
                           <NavLink
                              key={index}
                              to={item.path}
                              activeClassName="sidebar-nav-active"
                              className={"sidebar-nav-item mx-2 mb-1 "}
                           >
                              <div className="mx-3 px-2 py-3">
                                 <div className="d-flex justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center">
                                       {item.icon}
                                       <span className="fz13">{item.name}</span>
                                    </div>
                                 </div>
                              </div>
                           </NavLink>
                        )}
                        {item.children.length > 0 && item.show ? (
                           <div className="sidebar-nav-children mx-3 mt-2 pb-1 pt-2">
                              {item.children.map((item2) => {
                                 return (
                                    <Link key={item2.name} to={item2.path}>
                                       <div
                                          className={
                                             "d-flex justify-content-between align-items-center p-2 sidebar-nav-item mx-1 mb-1 " +
                                             (location.pathname ===
                                                item2.path ||
                                             location.pathname.includes(
                                                item2.path
                                             )
                                                ? "sidebar-nav-active"
                                                : "")
                                          }
                                       >
                                          <div className="d-flex align-items-center">
                                             {item2.icon}{" "}
                                             <span className="fz13">
                                                {item2.name}
                                             </span>
                                          </div>
                                       </div>
                                    </Link>
                                 );
                              })}
                           </div>
                        ) : (
                           ""
                        )}
                     </React.Fragment>
                  );
               })}
            </div>
         </div>
         <div className={"wrapper  " + (showSideBar ? "ml-0" : "")}>
            <div
               className="mb-2 d-flex w-100 justify-content-between align-items-center px-3 py-3 background-white"
               style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}
            >
               <div className="d-flex align-items-center">
                  <Image
                     src="img/menu.png"
                     className="cursor mr-2"
                     alt=""
                     onClick={() => setShowSideBar(!showSideBar)}
                  />
                  <div>
                     {menu.map((item, index) => {
                        return (
                           <React.Fragment key={item.path + index}>
                              {location.pathname.includes(item.path) &&
                                 item.path !== location.pathname &&
                                 item.children.length === 0 && (
                                    <div
                                       className="d-flex align-items-center justify-content-center"
                                       style={{ color: "#D4D3D6" }}
                                    >
                                       {item.icon}
                                       <span className="mr-2">::</span>
                                       <Link to={item.path}>
                                          <span style={{ color: "#0BADEE" }}>
                                             {item.name}
                                          </span>
                                       </Link>
                                       {item.breadrcumb !== undefined &&
                                       location.pathname.includes(item.path) &&
                                       item.breadrcumb.length
                                          ? item.breadrcumb.map((item2) => {
                                               return (
                                                  <React.Fragment
                                                     key={
                                                        item2.path + item2.name
                                                     }
                                                  >
                                                     {location.pathname.includes(
                                                        item2.path
                                                     ) ? (
                                                        <div>
                                                           <span className="mx-2">
                                                              /
                                                           </span>{" "}
                                                           {item2.name}
                                                        </div>
                                                     ) : (
                                                        ""
                                                     )}
                                                  </React.Fragment>
                                               );
                                            })
                                          : ""}
                                    </div>
                                 )}
                           </React.Fragment>
                        );
                     })}
                  </div>
               </div>

               <div className="position-relative">
                  <div
                     onClick={() => setShowSettings(!showSettings)}
                     className="mr-2 p-1 cursor d-flex rounded-pill bg-dark-grey"
                  >
                     <User
                        src="img/delivery-truck.png"
                        size={16}
                        fill="#fff"
                        color="#fff"
                        alt=""
                     />
                  </div>
                  {showSettings && (
                     <div
                        className="position-absolute box-shadow border br-5 bg-white"
                        style={{ right: 0, top: "50px" }}
                     >
                        <div className="d-flex align-items-center border-bottom p-2">
                           <div>
                              <img
                                 src="/img/grey.png"
                                 className="br-50 mr-2"
                                 width={36}
                                 height={36}
                                 alt=""
                              />
                           </div>
                           <div>
                              <div>
                                 <b>Администратор</b>
                              </div>
                              <div>
                                 <p className="mb-0 fz12">Администратор</p>
                              </div>
                           </div>
                        </div>
                        <div className="border-bottom">
                           <div className="d-flex align-items-center ml-2 p-2">
                              <Edit
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Мои данные</div>
                           </div>
                           <div className="d-flex align-items-center ml-2 p-2">
                              <Settings
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Настройки</div>
                           </div>
                        </div>
                        <div>
                           <div
                              onClick={() => signOut()}
                              className="d-flex align-items-center cursor ml-2 p-2"
                           >
                              <LogOut
                                 size={16}
                                 color="#9D9BA3"
                                 className="mr-2"
                              />
                              <div>Выйти</div>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
            <div>{children}</div>
         </div>
      </div>
   );
}

export default SideBar;
