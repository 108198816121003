import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {PGET} from "../../../api/adminApi";
import {log10} from "chart.js/helpers";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,

        },
    },
};

export function ChartRegion() {
    const [dashboardData, setDashboardData] = useState([]);
    async function getDashboardMonthCounts() {
        const data = await PGET("/services/admin/api/dashboard-region-counts/");
        if (data) {
            setDashboardData(data.data);
        }
    }

    const data = {
        labels: dashboardData?.map((item, i) => item.regionName),
        datasets: [
            {
                label: "Контрагенты",
                data: dashboardData?.map((item, i) => item.salesRequestCount),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Мастеры",
                data: dashboardData?.map((item, i) => item.mastersRequestCount),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };

    useEffect(() => {
        getDashboardMonthCounts();
    }, []);

    return (
        <Bar
            options={options}
            data={data}
        />
    );
}
