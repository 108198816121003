import React, {useEffect, useState} from "react";
import {Save, XCircle} from "react-feather";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {POST} from "../../../api/adminApi";

export default function ChangeStatus({
                                         modal: {show, user},
                                         setModal,
                                         getItems,
                                         statusOptions,
                                         setStatusOptions
                                     }) {
    const [statusOpt, setStatusOpt] = useState(null)
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        clearErrors,
    } = useForm()

    useEffect(() => {
        if (user.statusId === 2) {
            const updatedStatusOptions = statusOptions.filter((option) => option.name === 'Отказано')
            setStatusOptions(updatedStatusOptions)
        } else if ((user.statusId === 1 || user.statusId === 3) && (user.masterTotalPoint > user.requestedPoint)) {
            setStatusOptions(statusOptions)
        } else if ((user.statusId === 1 || user.statusId === 3) && (user.masterTotalPoint < user.requestedPoint)){
            const updatedStatusOptions = statusOptions.filter((option) => option.name === 'Отказано')
            setStatusOptions(updatedStatusOptions)
        }
    }, [])

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
        }
    };

    async function changeStatus(data) {
        const response = await POST(
            "/services/admin/api/master-change-point-status",
            {
                comment: data.comment,
                id: user.id,
                status: data.status.name,
                statusId: data.status.id,
            }
        );
        if (response && response.success === true) {
            getItems();
            onHide();
        }
    }

    function onHide() {
        setModal({show: false, user: null});
        setStatusOpt(null);
        setValue("comment", "");
        clearErrors();
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить статус
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(changeStatus)} autoComplete="off">
                    <div className="d-flex">
                        <div className="w-100">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Выберите статус</label>
                                    </div>
                                    <Select
                                        {...register("status", {
                                            required: true,
                                            value: statusOpt,
                                        })}
                                        value={statusOpt}
                                        placeholder="Выберите статус"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={statusOptions}
                                        onChange={selectStatus}
                                        className="w-75"
                                    />
                                </div>
                                {errors.status && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="mr-md-3 w-25">
                                        <label className="mb-0">Комментарии</label>
                                    </div>
                                    <textarea
                                        {...register("comment", {
                                            required: true,
                                        })}
                                        type="text"
                                        placeholder=""
                                        className="input-control w-75"
                                    />
                                </div>
                                {errors.comment && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
