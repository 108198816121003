import React, {useEffect} from "react";

const MasterInputs = ({
                    ownerName,
                    ownerPhone,
                    setOwnerName,
                    setOwnerPhone,
                    ownerNotFound,
                    setOwnerNotFound,
                    handleCheckCodeInput,
                    selectedUser,
                    code,
                    setCode
                }) => {

    const onCheck = (e) => {
        setOwnerNotFound(e.target.checked)
    }

    return (
        <>
            {selectedUser?.requestStatusId !== 2 && <div className="form-check my-3">
                <input type="checkbox" className="form-check-input" id="check"
                       onClick={onCheck}/>
                <label className="form-check-label" htmlFor="check">Владелец не известен</label>
            </div>}

            {selectedUser?.requestStatusId !== 2 && <form
                onSubmit={handleCheckCodeInput}
            >
                {/*{!ownerNotFound && <>*/}
                <div className='rounded box-shadow br-5'>
                    <input
                        type="text"
                        value={ownerName}
                        className="form-control my-2 box-shadow"
                        id="ownerName"
                        aria-describedby="inputOwnerName"
                        onChange={e => setOwnerName(e.target.value)}
                        placeholder="Имя владельца"
                        required={true}
                    />
                </div>
                <div className='rounded box-shadow br-5'>
                    <input
                        type="text"
                        value={ownerPhone}
                        className="form-control my-2 rounded box-shadow br-5"
                        id="ownerPhone"
                        maxLength={12}
                        aria-describedby="inputOwnerPhone"
                        onChange={e => setOwnerPhone(e.target.value)}
                        placeholder="Номер владельца"
                        required={true}
                    />
                    {ownerPhone.length !== 12 && (
                        <p style={{color: 'red'}}>Bведите ровно 12 символов</p>
                    )}
                </div>
                {/*</>}*/}
                {selectedUser?.source === 2 ?
                    <div className='rounded box-shadow br-5'>
                        <input
                            disabled
                            value={code}
                            type="text"
                            className="form-control"
                            placeholder="Введите серийный номер"
                            aria-describedby="button-addon2"
                        />
                    </div>
                    : (
                        <div className='rounded box-shadow br-5'>
                            <input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder="Введите серийный номер"
                                aria-describedby="button-addon2"
                            />
                        </div>
                    )}
                <div className='d-flex justify-content-end mt-3'>
                    <button
                        className="btn btn-success"
                        type="submit"
                        id="button-addon2"
                    >
                        Проверить
                    </button>
                </div>
            </form>}
        </>
    )
}

export default MasterInputs
