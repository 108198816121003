import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { HelpCircle, Search } from "react-feather";
import Select from "react-select";

import { PGET } from "../../../api/adminApi";

export default function StatusMaster() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageStatusMaster") || 0
    ); //for numeration and saving currentPage after refresh
    const [statusOptions, setStatusOptions] = useState([
        { id: 0, name: "Все" },
    ]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageStatusMaster", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageStatusMaster", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/history/masters-statuses-pageList",
            true,
            {
                statusId: statusOpt.id,
                page: currentPage,
                size: 15,
                search: value,
            }
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET(
                "/services/admin/api/request/status-helper"
            );
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }
        getStatusHelper();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ background: "#eef1ff", marginTop: "-0.4rem" }}>
            <div className="fz18 p-3 pl-3 d-flex align-items-center">
                <HelpCircle className="mr-2" color="#5C3EBA" />{" "}
                <span>Статусы по запросам мастеров</span>
            </div>

            <div
                className="m-3 gap-3"
                style={{ display: "grid", gridTemplateColumns: "auto 33%" }}
            >
                <div
                    className="d-flex justify-content-end w-100"
                    style={{ width: "150px" }}
                >
                    <Select
                        className="w-30"
                        value={statusOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={statusOptions}
                        onChange={selectStatus}
                    />
                </div>

                <div className="position-relative w-100">
                    <Search
                        size={14}
                        color="#9D9BA3"
                        className="input-search-icon"
                    />
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search box-shadow br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>
            </div>

            <div className="m-3">
                <table className="table background-white rounded box-shadow br-5">
                    <thead>
                        <tr className="backgroung-grey">
                            <th className="text-center">№</th>
                            <th className="text-start">Номер запроса</th>
                            <th className="text-start">Дата изменения</th>
                            <th className="text-start">Изменен с</th>
                            <th className="text-start">Изменен на</th>
                            <th className="text-start">Причина</th>
                        </tr>
                    </thead>

                    <tbody>
                        {items?.map((user, idx) => {
                            const {
                                changedTime,
                                id,
                                reason,
                                requestMasterId,
                                statusChangedFrom,
                                statusChangedFromName,
                                statusChangedTo,
                                statusChangedToName,
                            } = user;
                            return (
                                <tr key={id}>
                                    <td className="text-center pointer">
                                        {currentPage === 0
                                            ? idx + 1
                                            : // : currentPage * 10 + idx + 1} // for 10 items per page
                                              // for 15 items per page
                                              currentPage * 10 +
                                              idx +
                                              1 +
                                              5 * currentPage}
                                    </td>
                                    <td className="text-start">
                                        {requestMasterId}
                                    </td>
                                    <td className="text-start">
                                        {changedTime}
                                    </td>
                                    <td className="text-start w-10">
                                        <b
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                            className={`p-1 text-white rounded font-weight-500 d-block ${
                                                statusChangedFrom === 1
                                                    ? "bg-warning text-black"
                                                    : statusChangedFrom === 2
                                                    ? "bg-success"
                                                    : statusChangedFrom === 3
                                                    ? "bg-danger"
                                                    : "bg-primary"
                                            }`}
                                        >
                                            {statusChangedFromName}
                                        </b>
                                    </td>
                                    <td className="text-start w-10">
                                        <b
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                            className={`p-1 text-white rounded font-weight-500 d-block ${
                                                statusChangedTo === 1
                                                    ? "bg-warning text-black"
                                                    : statusChangedTo === 2
                                                    ? "bg-success"
                                                    : statusChangedTo === 3
                                                    ? "bg-danger"
                                                    : "bg-primary"
                                            }`}
                                        >
                                            {statusChangedToName}
                                        </b>
                                    </td>
                                    <td className="text-start">{reason}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}
        </div>
    );
}
