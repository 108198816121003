import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { XCircle, Save } from "react-feather";

import { DELETE } from "../../../api/adminApi";

export default function ContragentDeleteModal({
   deleteModal: {
      show,
      contragent: { id, name },
   },
   setDeleteModal,
   getItems,
}) {
   const [loading, setLoading] = useState(false);

   function onHide() {
      setDeleteModal({ show: false, contragent: null });
   }

   async function deleteContragent() {
      setLoading(true);
      const res = await DELETE("/services/admin/api/contragent/" + id);
      setLoading(false);
      if (res && res.success === true) {
         getItems();
         onHide();
      }
   }

   return (
      <Modal
         aria-labelledby="contained-modal-title-vcenter"
         centered
         show={show}
         onHide={onHide}
      >
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               <div className="w-90">
                  Вы действительно хотите удалить контрагента
                  <h5 className="purple-900">{name}</h5>
               </div>
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="d-flex justify-content-end gap-4">
               <button
                  disabled={loading}
                  type="button"
                  onClick={onHide}
                  className="btn btn-outline-success d-flex align-items-center"
               >
                  <XCircle size={14} className="mr-2" />
                  Отмена
               </button>

               <button
                  disabled={loading}
                  onClick={deleteContragent}
                  className="btn btn-outline-danger d-flex align-items-center"
               >
                  <Save size={14} className="mr-2" />
                  Да
               </button>
            </div>
         </Modal.Body>
      </Modal>
   );
}
