import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ShoppingBag } from 'react-feather';
import { Image } from 'react-bootstrap';

// import menu from './SidebarMenu'

function SideBar({ children }) {
	const location = useLocation();
	const [showSideBar, setShowSideBar] = useState(false)
	const [menu, setMenu] = useState([
		{
			icon: <ShoppingBag className="mr-2" size={16} />,
			name: 'Заказы',
			path: '/operator/orders',
			show: false,
			children: [],
			breadrcumb: [
				{
					name: 'Добавление',
					path: '/operator/orders/create'
				},
				{
					name: 'Редактирование',
					path: '/operator/orders/update/'
				},
			]
		},
		{
			icon: <ShoppingBag className="mr-2" size={16} />,
			name: 'Курьеры',
			path: '/operator/couriers',
			show: false,
			children: [],
			breadrcumb: []
		},
	])

	const showItem = (index, bool) => {
		let copy = [...menu];
		let copyItem = { ...menu[index] };
		copyItem.show = bool;
		copy[index] = copyItem;
		setMenu(copy);
	}

	useEffect(() => {
		for (let i = 0; i < menu.length; i++) {
			showItem(i, false)
			if (location.pathname.includes(menu[i].path)) {
				if (menu[i].breadrcumb !== undefined && menu[i].breadrcumb.length) {
					var copy = menu
					copy[i]['breadrcumb'][2] = location.pathname
					setMenu([...menu, copy])
				}
				if (menu[i].children.length > 0) {
					showItem(i, true)
				}
			}
		}
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<div className="sidebar sidebar-fixed" style={showSideBar ? { marginLeft: '-256px' } : {}}>
				<Link className="sidebar-brand pl-3" to="/">
					<Image src="/img/admin-logo.png" alt="" height={32} />
				</Link>
				<div className="sidebar-nav" style={{ paddingTop: '40px' }}>
					{menu.map((item, index) => {
						return (
							<React.Fragment key={item.name}>
								<NavLink key={index} to={item.path} activeClassName='sidebar-nav-active' className={'sidebar-nav-item mx-2 mb-1 '}>
									<div className="mx-3 px-2 py-3">
										<div className="d-flex justify-content-between align-items-center ">
											<div className="d-flex align-items-center">
												{item.icon}
												<span className="fz13" >{item.name}</span>
											</div>
										</div>
									</div>
								</NavLink>
							</React.Fragment>
						)
					})}

				</div>
			</div>
			<div className={'wrapper  ' + (showSideBar ? 'ml-0' : '')}>
				<div className="mb-2 d-flex w-100 justify-content-between px-3 py-3 background-white" style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' }}>
					<div className="d-flex align-items-center">
						<Image src="img/menu.png" className="cursor mr-2" alt="" onClick={() => setShowSideBar(!showSideBar)} />
						<div>
							{menu.map((item, index) => {
								return (
									<React.Fragment key={item.path + index}>
										{location.pathname.includes(item.path) && item.path !== location.pathname && item.children.length === 0 &&
											<div className="d-flex align-items-center justify-content-center" style={{ color: '#D4D3D6' }}>
												{item.icon}
												<span className="mr-2" >::</span>
												<Link to={item.path}><span style={{ color: '#0BADEE' }}>{item.name}</span></Link>
												{item.breadrcumb !== undefined &&
													location.pathname.includes(item.path) && item.breadrcumb.length ?
													item.breadrcumb.map((item2) => {
														return (
															<React.Fragment key={item2.path + item2.name}	>
																{
																	location.pathname.includes(item2.path) ?
																		<div><span className="mx-2">/</span> {item2.name} </div>
																		:
																		""
																}
															</React.Fragment>
														)
													}) : ''
												}
											</div>
										}
									</React.Fragment>
								)
							})}
						</div>
					</div>
					<div>
						<Image src="img/user.png" alt="" />
					</div>
				</div>
				<div>
					{children}
				</div>
			</div>
		</div>
	);
}

export default SideBar;
