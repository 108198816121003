import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {Search, HelpCircle, CheckSquare, Trash2} from "react-feather";
import {toast} from "react-toastify";
import Select from "react-select";

import Logo from "../../../assets/logo2.png";
import {PGET, POST} from "../../../api/adminApi";
import ChangeStatus from "./ChangeStatus";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import MasterDeleteModal from "../Masters/MasterDeleteModal";
import RequestMasterDeleteModal from "./RequestMasterDeleteModal";
import MasterInputs from "./MasterInputs";

function RequestFromMasters() {
    const requestOptions = [
        {
            id: 0,
            name: "Все"
        },
        {
            id: 1,
            name: "Телеграм"
        },
        {
            id: 2,
            name: "Bitrix"
        },
        {
            id: 3,
            name: "Application"
        }
    ]
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageRequestFromMastersNew") || 0
    ); //for numeration and saving currentPage after refresh
    const [selectedUser, setSelectedUser] = useState(null);
    const [code, setCode] = useState(""); // checkCode input
    const [selectImg, setSelectImg] = useState(true);
    const [statusOptions, setStatusOptions] = useState([{id: 0, name: "Все"}]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const [requestOpt, setRequestOpt] = useState(requestOptions[0]);
    const [modal, setModal] = useState({show: false, user: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        master: null,
    });
    const [ownerName, setOwnerName] = useState('')
    const [ownerPhone, setOwnerPhone] = useState('')
    const [ownerNotFound, setOwnerNotFound] = useState(false)
    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectRequestBy = (selectedOpt) => {
        if (requestOpt !== selectedOpt) {
            setRequestOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageRequestFromMastersNew", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageRequestFromMastersNew", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/request/masters-pageList",
            true,
            {source: requestOpt.id, statusId: statusOpt.id, page: currentPage, size: 15, search: value}
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
        setCode("");
    }

    async function handleCheckCodeInput(e) {
        e.preventDefault();
        const response = await POST(
            "/services/admin/api/request/masters/checkCode",
            {code, requestCode: selectedUser.id, ownerName, ownerNotFound, ownerPhone}
        );
        console.log(code, selectedUser.id, ownerName, ownerNotFound, ownerPhone)
        toast.info(response?.message);
        setCode('')
        setOwnerPhone('')
        setOwnerName('')
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value, requestOpt]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedUser(
            (prev) =>
                items &&
                (prev
                    ? items.find((item) => item.id === prev.id) === undefined
                        ? items[0]
                        : items.find((item) => item.id === prev.id)
                    : items[0])
        );
    }, [items]);

    useEffect(() => {
        setOwnerNotFound(!(selectedUser?.ownerName && selectedUser?.ownerPhone))
    }, [selectedUser]);

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET("/services/admin/api/request/status-helper");
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();

        async function getChangeStatusOptions() {
            const res = await PGET(
                "/services/admin/api/request/status-helper-change"
            );
            if (res) {
                setChangeStatusOptions(res.data);
            }
        }

        getChangeStatusOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    function getRequest(id) {
        switch (id) {
            case 0:
                return 'Все системы';
            case 1:
                return 'От Телеграм';
            case 2:
                return 'От Bitrix';
            default:
                return '';
        }
    }

    return (
        <div style={{background: "#eef1ff", marginTop: "-0.4rem"}}>
            <div className="fz18 p-3 pl-3 d-flex align-items-center">
                <HelpCircle className="mr-2" color="#5C3EBA"/>{" "}
                <span>Запросы от мастеров</span>
            </div>

            <div
                className="m-3 gap-3"
                style={{display: "grid", gridTemplateColumns: "auto 33%"}}
            >
                <div
                    className="d-flex align-items-center justify-content-end w-100 gap-3"
                    style={{width: "150px"}}
                >
                    <Select
                        className="w-20"
                        value={requestOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={requestOptions}
                        onChange={selectRequestBy}
                        // styles={styles}
                    />
                    <Select
                        className="w-20"
                        value={statusOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={statusOptions}
                        onChange={selectStatus}
                    />

                </div>

                <div className="position-relative w-100">
                    <Search
                        size={14}
                        color="#9D9BA3"
                        className="input-search-icon"
                    />
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search box-shadow br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>

                <table className="table background-white rounded box-shadow br-5 align-self-start">
                    <thead>
                    <tr className="backgroung-grey">
                        <th className="text-center">№</th>
                        <th className="text-start">Запрос</th>
                        <th className="text-start">Bitrix/TG/App</th>
                        <th className="text-start">Продукт</th>
                        <th className="text-start">Объект</th>
                        <th className="text-center" style={{width: "5%"}}>Статус</th>
                        <th className="text-center" style={{width: "5%"}}>Балл</th>
                        <th className="text-center"></th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items?.map((user, idx) => {
                        const {
                            id,
                            // telegramId,
                            // userId,
                            receivedDate,
                            phone,
                            requestStatus,
                            requestStatusId,
                            earnedPoint,
                            requestCountBitrix,
                            requestCountTelegram,
                            requestCountApplication,
                            productBrand,
                            productName,
                            objectName,
                            tir
                            // fileId,
                            // filePathCode,
                            // filePathScratchCode,
                            // finishedStatus,
                            // masterName,
                            // cityHelperDTO,
                            // regionHelperDTO,
                            // rejectComment,
                        } = user;
                        return (
                            <tr
                                onClick={() => {
                                    setSelectedUser(user);
                                    setOwnerNotFound(!(ownerName && ownerPhone))
                                    // setCode(serial ? serial : '')
                                    setOwnerName(ownerName ? ownerName : '')
                                    setOwnerPhone(ownerPhone ? ownerPhone : '')
                                }}
                                role="button"
                                className={
                                    id === selectedUser?.id ? "box-shadow" : ""
                                }
                                style={{
                                    background: `${
                                        id === selectedUser?.id ? "#eef1ff" : "white"
                                    }`,
                                }}
                                key={id}
                            >
                                <td className="text-center pointer">
                                    {currentPage === 0
                                        ? idx + 1
                                        : // : currentPage * 10 + idx + 1} // for 10 items per page
                                          // for 15 items per page
                                        currentPage * 10 + idx + 1 + 5 * currentPage}
                                </td>
                                <td className="text-start">
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Номер: </p>
                                            <p className="p-0 ml-2 mb-0">{id}</p>

                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Время: </p>
                                            <p className="p-0 ml-2 mb-0"> {new Date(receivedDate).toLocaleString()}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Tелефон: </p>
                                            <p className="p-0 ml-2 mb-0">{phone}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-start">
                                    <div className='d-flex'>
                                 <span style={{backgroundColor: '#FFA500', minWidth: '30px'}}
                                       className='text-white p-1 rounded mr-2 text-center'> {`${requestCountBitrix}`}</span>
                                        <span style={{backgroundColor: '#2AABEE', minWidth: '30px'}}
                                              className='text-white p-1 rounded mr-2 text-center'>{`${requestCountTelegram}`}</span>
                                        <span style={{backgroundColor: '#11816a', minWidth: '30px'}}
                                              className='text-white p-1 rounded text-center'>{`${requestCountApplication}`}</span>
                                    </div>
                                </td>
                                <td className="text-start">
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Бренд: </p>
                                            <p className="p-0 ml-2 mb-0"> {productBrand}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Модель: </p>
                                            <p className="p-0 ml-2 mb-0">{productName}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-start">
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Объект: </p>
                                            <p className="p-0 ml-2 mb-0"> {objectName}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="m-0 p-0 font-bold text-#eff2f5">Тир: </p>
                                            <p className="p-0 ml-2 mb-0">{tir}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className={`text-start`}>
                                    <b
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                        className={`p-1 text-white rounded font-weight-500 d-block ${
                                            requestStatusId === 1
                                                ? "bg-warning text-black"
                                                : requestStatusId === 2
                                                    ? "bg-success"
                                                    : requestStatusId === 3
                                                        ? "bg-danger"
                                                        : "bg-primary"
                                        }`}
                                    >
                                        {requestStatus}
                                    </b>
                                </td>
                                <td className="text-center">{earnedPoint}</td>
                                <td className="text-center">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModal({show: true, user});
                                        }}
                                        className="btn btn-table box-shadow br-5"
                                        style={{backgroundColor: "#F4F4F5"}}
                                    >
                                        <CheckSquare color={"#5C3EBA"} size={16}/>
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteModal({
                                                show: true,
                                                master: user,
                                            });
                                        }}
                                        className="btn btn-table mr-0 box-shadow br-5"
                                        style={{backgroundColor: "#F4F4F5"}}
                                    >
                                        <Trash2 color={"#E63950"} size={16}/>
                                    </button>
                                </td>

                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                <div className="h-100">
                    <table className="table background-white rounded box-shadow br-5">
                        <tbody>
                        <tr>
                            <th>Мастер:</th>
                            <td className="text-end">{selectedUser?.masterName}</td>
                        </tr>
                        <tr>
                            <th>Регион:</th>
                            <td className="text-end">
                                {selectedUser?.regionHelperDTO?.name}
                            </td>
                        </tr>
                        <tr>
                            <th>Город/Район:</th>
                            <td className="text-end">
                                {selectedUser?.cityHelperDTO?.name}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {selectedUser?.requestStatusId === 4 && (
                        <table className="table background-white rounded box-shadow br-5">
                            <tbody>
                            <tr>
                            <th>Установлен Мастером:</th>
                                <td className="text-end">
                                    {selectedUser?.mountedMasterName}
                                </td>
                            </tr>
                            <tr>
                                <th>Запрос установки:</th>
                                <td className="text-end">
                                    {selectedUser?.mountedRequestId}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}

                    <table className="table background-white rounded box-shadow br-5">
                        <tbody>
                        <tr>
                            <th>Заметка:</th>
                            <td className="text-end">{selectedUser?.masterComment}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div
                        className="rounded box-shadow br-5 background-white d-flex items-center justify-content-center">
                        <TransformWrapper>
                            <TransformComponent>
                                <img
                                    style={{
                                        width: "100%",
                                        maxHeight: "550px",
                                        minHeight: "330px",
                                    }}
                                    src={`${
                                        selectedUser?.id
                                            ? "https://bo.airfelsales.uz/" +
                                            (selectImg
                                                ? selectedUser.filePathCode
                                                : selectedUser.filePathScratchCode)
                                            : Logo
                                    }`}
                                    alt="img"
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>

                    <div className="my-3 rounded br-5 d-flex box-shadow">
                        {/*<div*/}
                        {/*    onClick={() => setSelectImg(true)}*/}
                        {/*    className={`btn ${*/}
                        {/*        selectImg && "btn-info text-white"*/}
                        {/*    } w-50 font-weight-500`}*/}
                        {/*>*/}
                        {/*   Фото №1*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    onClick={() => setSelectImg(false)}*/}
                        {/*    className={`btn ${*/}
                        {/*        !selectImg && "btn-info text-white"*/}
                        {/*    } w-50 font-weight-500`}*/}
                        {/*>*/}
                        {/*   Фото №2*/}
                        {/*</div>*/}
                    </div>


                    {/*{selectedUser?.requestStatusId !== 2 && (*/}
                    {/*   <form*/}
                    {/*      onSubmit={handleCheckCodeInput}*/}
                    {/*      className="input-group my-3 rounded box-shadow br-5"*/}
                    {/*   >*/}
                    {/*      <input*/}
                    {/*         value={code}*/}
                    {/*         onChange={(e) => setCode(e.target.value)}*/}
                    {/*         type="text"*/}
                    {/*         className="form-control"*/}
                    {/*         placeholder="Введите серийный номер"*/}
                    {/*         aria-describedby="button-addon2"*/}
                    {/*         required*/}
                    {/*      />*/}
                    {/*      <button*/}
                    {/*         className="btn btn-success"*/}
                    {/*         type="submit"*/}
                    {/*         id="button-addon2"*/}
                    {/*      >*/}
                    {/*         Проверить*/}
                    {/*      </button>*/}
                    {/*   </form>*/}
                    {/*)}*/}

                    <MasterInputs ownerName={ownerName} ownerPhone={ownerPhone} setOwnerPhone={setOwnerPhone}
                                  setOwnerName={setOwnerName} ownerNotFound={ownerNotFound}
                                  setOwnerNotFound={setOwnerNotFound} handleCheckCodeInput={handleCheckCodeInput}
                                  selectedUser={selectedUser} code={code} setCode={setCode}/>

                </div>
            </div>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}
            {deleteModal.master && (
                <RequestMasterDeleteModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    getItems={getItems}
                />
            )}
            {modal.user && (
                <ChangeStatus
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
        </div>
    );
}

export default RequestFromMasters;
