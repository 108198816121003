import React, { useEffect, useState } from "react";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { PGET } from "../../api/adminApi";

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

const options = {
   responsive: true,
   plugins: {
      legend: {
         position: "top",
      },
      title: {
         display: true,

      },
   },
};

const labels = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

export function Chart() {
   const [dashboardData, setDashboardData] = useState([]);

   async function getDashboardMonthCounts() {
      const data = await PGET("/services/admin/api/dashboard-month-counts/");
      if (data) {
         setDashboardData(data.data);
      }
   }

   const data = {
      labels: labels,
      datasets: [
         {
            label: "Контрагенты",
            data: dashboardData?.map((item, i) => item.salesRequestCount),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
         },
         {
            label: "Мастеры",
            data: dashboardData?.map((item, i) => item.mastersRequestCount),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
         },
      ],
   };

   useEffect(() => {
      getDashboardMonthCounts();
   }, []);

   return (
      <Bar
         options={options}
         data={data}
      />
   );
}
