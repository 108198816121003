import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { PGET, DELETE } from "../../../api/adminApi";
import {Link} from "react-router-dom";
import {Edit3, PlusCircle, Trash2} from "react-feather";

export default function Product() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPagePrizes") || 0
    ); //for numeration and saving currentPage after refresh

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPagePrizes", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPagePrizes", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/product-pageList", true, {
            page: currentPage,
            size: 10,
            search: value,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    console.log(items)
    async function deleteItem(id) {
        await DELETE("/services/admin/api/product/" + id);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5 flex-row'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Продукты</span>
                </h3>
                    <Link to="/admin/products/create">
                        <button className="btn btn-success d-flex align-items-center box-shadow">
                            <PlusCircle size={18} className="mr-1" />
                            <div>Добавить продукт</div>
                        </button>
                    </Link>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="position-relative">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div style={{ overflow: "auto", maxHeight: '75vh' }}>
                    <table className='table bg-white table-rounded p-0 border gy-3 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopLeftRadius: '8px', width: "30px"}} className="text-white text-center">№</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Наименование</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Бренд</th>
                            <th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Модель</th>
                            <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Балл для мастера</th>
                            <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700"}}>Балл для контрагента</th>
                            <th className="text-center text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>
                            {/*<th className="text-start text-white" style={{backgroundColor: '#E7372C', fontWeight: "700", borderTopRightRadius: '8px'}}></th>*/}
                        </tr>
                        </thead>

                        <tbody style={{ overflowX: "auto" }}>
                        {items.map((item, idx) => {
                            return (
                                <tr key={item.id} className='borderBottom'>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? idx + 1
                                            : currentPage * 10 + idx + 1}
                                    </td>
                                    <td className="text-start">{item.name}</td>
                                    <td className="text-start">{item.brandName}</td>
                                    <td className="text-start">{item.modelName}</td>
                                    <td className="text-center">{item.point}</td>
                                    <td className="text-center">{item.pointSales}</td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <Link
                                                to={"/admin/products/update/" + item.id}
                                            >
                                                <button
                                                    className="btn btn-table"
                                                    style={{backgroundColor: "#F4F4F5"}}
                                                >
                                                    <Edit3 color={"#189ED3"} size={16}/>
                                                </button>
                                            </Link>
                                            <button
                                                onClick={() => deleteItem(item.id)}
                                                className="btn btn-table mr-0"
                                                style={{backgroundColor: "#F4F4F5"}}
                                            >
                                                <Trash2 color={"#E63950"} size={16}/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"bg-dark text-white"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
        </div>
    );
}

