import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {HelpCircle, CheckSquare, Search} from "react-feather";
import Select from "react-select";

import {PGET} from "../../../api/adminApi";
import ChangeStatus from "./ChangeStatus";

function RequestToExchange() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageRequestToExchange") || 0
    ); //for numeration and saving currentPage after refresh
    const [selectedUser, setSelectedUser] = useState(null);
    const [statusOptions, setStatusOptions] = useState([
        {id: 0, name: "Все"},
    ]);
    const [statusOpt, setStatusOpt] = useState(statusOptions[0]);
    const [modal, setModal] = useState({show: false, user: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);

    const selectStatus = (selectedOpt) => {
        if (statusOpt !== selectedOpt) {
            setStatusOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageRequestToExchange", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected); //for numeration
        localStorage.setItem("currentPageRequestToExchange", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET(
            "/services/admin/api/master-change-point-pageList",
            true,
            {
                status: statusOpt.id,
                page: currentPage,
                size: 15,
                search: value,
                region: 0,
                user: 1
            }
        );
        setPageCount(Math.ceil(data?.headers["x-total-count"] / 15));
        setItems(data?.data);
    }

    useEffect(() => {
        getItems();
    }, [statusOpt, currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getStatusHelper() {
            const data = await PGET(
                "/services/admin/api/request/status-helper"
            );
            if (data) {
                setStatusOptions([statusOptions[0], ...data.data]);
            }
        }

        getStatusHelper();

        async function getChangeStatusOptions() {
            const res = await PGET(
                "/services/admin/api/request/status-helper-point-change"
            );
            if (res) {
                setChangeStatusOptions(res.data);
            }
        }

        getChangeStatusOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="p-3"
            style={{background: "#eef1ff", marginTop: "-0.4rem"}}
        >
            <div className="fz18 p-3 pl-3 d-flex align-items-center">
                <HelpCircle className="mr-2" color="#5C3EBA"/>{" "}
                <span>Запросы на обмен</span>
            </div>

            <div
                className="gap-3 mb-3"
                style={{display: "grid", gridTemplateColumns: "auto 33%"}}
            >
                <div
                    className="d-flex justify-content-end w-100"
                    style={{width: "150px"}}
                >
                    <Select
                        className="w-30"
                        value={statusOpt}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={statusOptions}
                        onChange={selectStatus}
                    />
                </div>

                <div className="position-relative w-100">
                    <Search
                        size={14}
                        color="#9D9BA3"
                        className="input-search-icon"
                    />
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        value={value}
                        onChange={handleSearchInput}
                        type="text"
                        className="input-search box-shadow br-5 w-100"
                        size={24}
                        placeholder="Поиск"
                    />
                </div>
            </div>

            <table className="table background-white rounded box-shadow br-5 align-self-start">
                <thead>
                <tr className="backgroung-grey">
                    <th className="text-center">№</th>
                    <th className="text-start">Мастер</th>
                    <th className="text-start">Дата получения</th>
                    <th className="text-start">Откуда</th>
                    <th className="text-start">Приз</th>
                    <th className="text-start">Текущий балл</th>
                    <th className="text-start">Цена приза</th>
                    <th className="text-start">Балл на обмен</th>
                    <th className="text-start">Причина</th>
                    <th className="text-center">Статус</th>
                    <th className="text-center"></th>
                </tr>
                </thead>

                <tbody style={{overflowX: "auto"}}>
                {items?.map((user, idx) => {
                    const {
                        id,
                        masterFullname,
                        masterRegion,
                        phone,
                        receivedTime,
                        requestedPoint,
                        reason,
                        pointOrPrize,
                        prizeName,
                        statusId,
                        statusName,
                        fromApp,
                        fromAppPrizeName,
                        fromAppPrizePrice,
                        masterTotalPoint
                    } = user;
                    return (
                        <tr
                            onClick={() => {
                                setSelectedUser(user);
                            }}
                            role="button"
                            className={
                                id === selectedUser?.id ? "box-shadow" : ""
                            }
                            style={{
                                background: `${
                                    id === selectedUser?.id
                                        ? "#eef1ff"
                                        : "white"
                                }`,
                            }}
                            key={id}
                        >
                            <td className="text-center pointer">
                                {currentPage === 0
                                    ? idx + 1
                                    : // : currentPage * 10 + idx + 1} // for 10 items per page
                                      // for 15 items per page
                                    currentPage * 10 +
                                    idx +
                                    1 +
                                    5 * currentPage}
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column w-fit-content'>
                                    <span>{`ФИО: ${masterFullname || ''}`}</span>
                                    <span>{`Регион: ${masterRegion || ''}`}</span>
                                    <span>{`Номер телефона: ${phone || ''}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                {new Date(receivedTime).toLocaleString()}
                            </td>
                            <td className="text-start">
                                {fromApp ? "Приложение" : "Telegram"}
                            </td>
                            <td className="text-start">
                                {fromAppPrizeName}
                            </td>
                            <td className="text-start">
                                {masterTotalPoint}
                            </td>
                            <td className="text-start">
                                {fromAppPrizePrice}
                            </td>
                            <td className="text-center">
                                {requestedPoint}
                            </td>
                            <td className="text-center">{reason}</td>
                            <td className={`text-start w-10`}>
                                <b
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                    className={`p-1 text-white rounded font-weight-500 d-block ${
                                        statusId === 1
                                            ? "bg-warning text-black"
                                            : statusId === 2
                                                ? "bg-success"
                                                : statusId === 3
                                                    ? "bg-danger"
                                                    : "bg-primary"
                                    }`}
                                >
                                    {statusName}
                                </b>
                            </td>
                            <td className="text-center">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setModal({show: true, user});
                                    }}
                                    className="btn btn-table box-shadow br-5"
                                    style={{
                                        backgroundColor: "#F4F4F5",
                                    }}
                                >
                                    <CheckSquare
                                        color={"#5C3EBA"}
                                        size={16}
                                    />
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {pageCount > 1 && (
                <ReactPaginate
                    forcePage={currentPage}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={paginate}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item box-shadow br-5"}
                />
            )}

            {modal.user && (
                <ChangeStatus
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                    setStatusOptions={setChangeStatusOptions}
                />
            )}
        </div>
    );
}

export default RequestToExchange;
